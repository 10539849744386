import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { CardMedia, InputAdornment, TextField } from "@mui/material";
import DasboardCard from "../dashboard-card/DasboardCard";
import { Search, SearchIcon, SlidersHorizontal } from "lucide-react";
import { userOrderapi } from "../../Maid-api";

const {
  getAllPendingOrder,
  getAllOngingOrder,
  getAllComplectedOrder,
  completeUserOrder,
} = userOrderapi;

const Dashboard = () => {
  const [updatelisttab, setUpdatelisttab] = useState(1);
  const [pendingOrder, setPendingOrder] = useState([]);
  const [ongoingOrder, setOngoingOrder] = useState([]);
  const [completeOrder, setCopleteOrder] = useState([]);

  // function Updatelist(id) {
  //   setUpdatelisttab(id);
  // }
  console.log("pendingOrder", pendingOrder);
  // Handel search service wise user order

  useEffect(() => {
    for (let i = 0; i < ongoingOrder?.length; i++) {
      const toDate = new Date(ongoingOrder[i]?.to_date);
      const currentDate = new Date();

      if (currentDate > toDate) {
        console.log("hii");
        completeUserOrder(ongoingOrder[i]?._id)
          .then((res) => {
            console.log("res:", res);
            handleOngoingOrder();
            handelCompletedOrder();
          })
          .catch((error) => {
            console.log("err:", error);
          });
      }
    }
  }, [ongoingOrder]);

  const HandelSearch = (event) => {
    console.log(event.target.value);

    getAllPendingOrder(event.target.value)
      .then((res) => {
        console.log("res", res?.pendingOrders);
        if (res) {
          setPendingOrder(res?.pendingOrders);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };

  // Handel ongoing serach
  const HandelOngoingSearch = (event) => {
    console.log(event.target.value);
    getAllOngingOrder(event.target.value)
      .then((res) => {
        console.log("Ongoing data", res?.ongingOrders);
        setOngoingOrder(res?.ongingOrders);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  //Handel search completed
  const HandelsearchCompeted = (event) => {
    console.log(event.target.value);
    getAllComplectedOrder(event.target.value)
      .then((res) => {
        console.log("completedOrders ", res?.completedOrders);
        setCopleteOrder(res?.completedOrders);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // get all pending user order
  useEffect(() => {
    getAllPendingOrder()
      .then((res) => {
        console.log("res------", res?.pendingOrders);
        if (res) {
          setPendingOrder(res?.pendingOrders);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);

  // call back function for pending order
  const handlePendingOrder = () => {
    getAllPendingOrder()
      .then((res) => {
        console.log("res------", res?.pendingOrders);
        if (res) {
          setPendingOrder(res?.pendingOrders);
        }
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
  // get all ongoing user order

  useEffect(() => {
    getAllOngingOrder()
      .then((res) => {
        console.log("Ongoing data", res?.ongingOrders);
        setOngoingOrder(res?.ongingOrders);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  // call back function for ongoing order
  const handleOngoingOrder = () => {
    getAllOngingOrder()
      .then((res) => {
        console.log("Ongoing data", res?.ongingOrders);
        setOngoingOrder(res?.ongingOrders);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // get all completed user order
  useEffect(() => {
    getAllComplectedOrder()
      .then((res) => {
        console.log("completedOrders ", res?.completedOrders);
        setCopleteOrder(res?.completedOrders);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  // call back function for completed order
  const handelCompletedOrder = () => {
    getAllComplectedOrder()
      .then((res) => {
        console.log("completedOrders ", res?.completedOrders);
        setCopleteOrder(res?.completedOrders);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  function Pending() {
    return (
      <div className="pending-function-container">
        {pendingOrder?.map((item) => (
          <DasboardCard
            keyy={1}
            items={item}
            handlePendingOrder={handlePendingOrder}
            handleOngoingOrder={handleOngoingOrder}
            handelCompletedOrder={handelCompletedOrder}
          />
        ))}
      </div>
    );
  }

  // it displays the pending page with dashboard card when user clicks on ongoing tab
  function Ongoing() {
    return (
      <div className="pending-function-container">
        {ongoingOrder?.map((item) => (
          <DasboardCard
            keyy={2}
            items={item}
            handlePendingOrder={handlePendingOrder}
            handleOngoingOrder={handleOngoingOrder}
            handelCompletedOrder={handelCompletedOrder}
          />
        ))}
      </div>
    );
  }

  // it displays the pending page with dashboard card when user clicks on completed tab
  function Completed() {
    return (
      <div className="pending-function-container">
        {completeOrder?.map((item) => (
          <DasboardCard
            keyy={3}
            items={item}
            handlePendingOrder={handlePendingOrder}
            handleOngoingOrder={handleOngoingOrder}
            handelCompletedOrder={handelCompletedOrder}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="parent-container-of-dasboard-page">
      <div className="list-items-of-dashboard-page">
        <ul className="tabs-heading-conatiner">
          <li
            className={updatelisttab === 1 ? "activee" : "notactive"}
            onClick={() => setUpdatelisttab(1)}
          >
            Pending
          </li>
          <li
            className={updatelisttab === 2 ? "activee" : "notactive"}
            onClick={() => setUpdatelisttab(2)}
          >
            Ongoing
          </li>
          <li
            className={updatelisttab === 3 ? "activee" : "notactive"}
            onClick={() => setUpdatelisttab(3)}
          >
            Completed
          </li>
        </ul>

        <div className={updatelisttab === 1 ? "show-content" : "content"}>
          <div className="search-and-filter-container">
            {" "}
            <div className="search-icon-container">
              <TextField
                style={{
                  marginLeft: "3vw",
                  height: "5.8vh",
                  backgroundColor: "white",
                }}
                onChange={HandelSearch}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <Pending />
        </div>
        <div className={updatelisttab === 2 ? "show-content" : "content"}>
          <div className="search-and-filter-container">
            {/* <div class="search-bar"> */}
            <div className="search-icon-container">
              <TextField
                style={{
                  marginLeft: "3vw",
                  height: "5.8vh",
                  backgroundColor: "white",
                }}
                placeholder="Search..."
                onChange={HandelOngoingSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <Ongoing />
        </div>
        <div className={updatelisttab === 3 ? "show-content" : "content"}>
          <div className="search-and-filter-container">
            {/* <div class="search-bar"> */}
            <div className="search-icon-container">
              <TextField
                style={{
                  marginLeft: "3vw",
                  height: "5.8vh",
                  backgroundColor: "white",
                }}
                placeholder="Search..."
                onChange={HandelsearchCompeted}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          <Completed />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
