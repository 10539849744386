import { ActionTypes } from "./constants";

const initialState = {
    loginDetails: {},
    location: '/'
};

const logindetailsreducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_LOGINDETAILS:
            return { ...state, loginDetails: action.payload };
        case ActionTypes.REDIRECT:
            return { ...state, location: action.payload };
        default:
            return state;
    }
}

export default logindetailsreducer;
