export default {
    
   API_URL: "https://api.quickji.com",
    // API_URL: "http://localhost:8080",
    //API_URL: "http://192.168.1.51:8080",
    // API_URL: "http://mymaidapidev-env.eba-pfnkzncj.ap-south-1.elasticbeanstalk.com/",


    DEFAULT_HEADERS: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };