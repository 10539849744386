import axios from "axios";
import constants from "./constants";
import store from "../store";

axios.defaults.baseURL = constants.API_URL;
let refreshTimer = null;


/**
 * Set the authentication header for all the outgoing network calls to protect the APIs.
 * @param {*} token
 */
const setAuthHeader = (token) => {
  console.log("Bearer " , token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.defaults.withCredentials = true;
  };

  
/**
 * Set the token obtained in local storage, after authenticating.
 * @param {*} tokenName
 * @param {*} token
 */
const setToken = async (tokenName, token) => {
    await localStorage.setItem(tokenName, token);
  };

  /**
 * Remove token from local storage.
 * @param {*} tokenName
 */
const removeToken = async (tokenName) => {
    await localStorage.removeItem(tokenName);
  };

  const setUserToken = async (token, refreshToken) => {
    await setToken("userToken", token);
    await setToken("refreshToken", refreshToken);
    setAuthHeader(token);
  };

  const instance = axios.create({});
instance.interceptors.request.use(async (config) => {
  const value = localStorage.getItem("userToken");
  config.headers = {
    Authorization: `Bearer ${value}`,
    Accept: "application/json",
  };
  store.dispatch({ type: "spin", payload: true });
  return config;
});
instance.interceptors.response.use(
  function (response) {
    store.dispatch({ type: "spin", payload: false });
    return response;
  },
  function (error) {
    const res = error.response;
    store.dispatch({ type: "spin", payload: false });

    if (res.status === 401) {
      window.localStorage.removeItem("persist:root");
      window.localStorage.removeItem("userToken");
      window.localStorage.removeItem("refreshToken");
    
    }
    return Promise.reject(error);
  }
);
  
  /**
   * Ragister by name and mobile number
   */
  
  const register = async(name, phoneNumber) =>{
    console.log(phoneNumber,"phone",name,"name");
    try {
      let response=await axios.post("/api/auth/sign-up",
       {
        full_name: name,
        mobile_number:phoneNumber
        },
        {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST",
            },
          }
        );
      
        //  await setUserToken(response.data["token"], response.data["token"]);
          // const remainingTime = calculateRemainingTime(response.data.expirationTime);
          return response.data;
        } catch (error) {
          // await signout();
          
          throw error;
        }
  }
/**
 * verify otp by mobile number
 */
const VerifyOtp = async( phoneNumber,verifyId,otp) =>{
 
  try {
    let response=await axios.post("/api/auth/verify-otp",
     {
      
      mobile_number:phoneNumber,
      verify_id:verifyId,
      otp:otp,
      },
      {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
          },
        }
      );
   
       await setUserToken(response.data["token"], response.data["token"]);
        // const remainingTime = calculateRemainingTime(response.data.expirationTime);
        return response.data;
      } catch (error) {
        // await signout();
        throw error;
      }
}


/**
 * create mpin  by mobile number 
 */
const CreateMpin = async (mpin) => {
  try {
    const response = await instance.put(
      "/api/auth/mpin-setup",
      {
      mpin:mpin
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "PUT",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * verify user  by mobile number 
 */
const verifyUser = async (mobile_number) => {
  try {
    const response = await instance.post(
      "/api/auth/verify-user",
      {
        mobile_number:mobile_number
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * sign in by passing mobile number and mpin
 *
 */
const signin = async (pNo, mpin) => {
  try {
    const response = await axios.post(
      "/api/auth/login",
      {
        mobile_number: pNo,
        mpin: mpin
      },
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      }
    );
    
    await setUserToken(response.data["token"], response.data["token"]);
    // const remainingTime = calculateRemainingTime(response.data.expirationTime);
    return response.data;
  } catch (error) {
    // await signout();
    throw error;
  }
};

// get all user Detail
const getUserDetail =async() =>{
  try{
      const response = await instance.get("/api/user-details",
      
    
      {
          headers:{
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":"GET"
          },
      }
    );
    return response.data;
  }catch(error){
      throw error;
  }
};



/**
 * signout
 */
async function signout() {
  
  clearTimeout(refreshTimer);
  await removeToken("userToken");
  await removeToken("refreshToken");
 

  axios.defaults.headers.common["Authorization"] = null;
}



  export default {
    register,
    VerifyOtp,
    CreateMpin,
    signin,
    signout,
    setAuthHeader,
    getUserDetail,
    verifyUser
    
  }