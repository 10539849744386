import axios from "axios";
import constants from "./constants";
import store from "../store";

axios.defaults.baseURL = constants.API_URL;
const instance = axios.create({});
instance.interceptors.request.use(async (config) => {
  const value = localStorage.getItem("userToken");
  config.headers = {
    Authorization: `Bearer ${value}`,
    Accept: "application/json",
  };
  store.dispatch({ type: "spin", payload: true });
  return config;
});


  
instance.interceptors.response.use(
    function (response) {
      store.dispatch({ type: "spin", payload: false });
      return response;
    },
    function (error) {
      const res = error.response;
      store.dispatch({ type: "spin", payload: false });
  
      if (res.status === 401) {
        window.localStorage.removeItem("persist:root");
        window.localStorage.removeItem("userToken");
        window.localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  const setAuthHeader = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.defaults.withCredentials = true;
  };


  //get all services
  const getAllServices =async() =>{
    try{
        const response = await instance.get("/api/all-service",
         {
            params: { pageSize:100, is_active: true },
          },
      
        {
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":"GET"
            },
        }
      );
      return response.data;
    }catch(error){
        throw error;
    }
  };

   /**
   * create new user address
   * @param {*} newAddress object
   * @returns
   */

   const createUserAddress =async(newAddress) => {
    try{
        const response= await instance.post("api/add-user-address",newAddress,
        {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods": "POST",
            },
 
        }
      );
      return response.data;
      
    }catch(error){
        throw error;
    }
  };
  
  //get all user Address
  const getAllApartments =async() =>{
    try{
        const response = await instance.get("/api/all-apartment",
         {
            params: { is_active: true },
          },
      
        {
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":"GET"
            },
        }
      );
      return response.data;
    }catch(error){
        throw error;
    }
  };
//get all user address
  const getAllAddress =async() =>{
    try{
        const response = await instance.get("/api/get-user-address",
        
      
        {
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":"GET"
            },
        }
      );
      return response.data;
    }catch(error){
        throw error;
    }
  };

  // update user profile detail
const updateUserProfile =async(data) => {
  try{
      const response= await instance.put("api/updateUserProfile",data,
      {
          headers:{
              "Access-Control-Allow-Origin":"*",
              "Access-Control-Allow-Methods": "PUT",
          },

      }
    );
    return response.data;
    
  }catch(error){
      throw error;
  }
};

// app feedback give
const giveAppFeedback =async(data) => {
  try{
      const response= await instance.post("api/sendAppFeedback",data,
      {
          headers:{
              "Access-Control-Allow-Origin":"*",
              "Access-Control-Allow-Methods": "POST",
          },

      }
    );
    return response.data;
    
  }catch(error){
      throw error;
  }
};
  export default {
    setAuthHeader,
    getAllServices,
    createUserAddress,
    getAllApartments,
    getAllAddress,
    updateUserProfile,
    giveAppFeedback
  }