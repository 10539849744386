import { combineReducers } from "redux";
import loginDetails from "./redux-core/authentication";



const appReducer = combineReducers({
  loginDetails,
})

const initialState = {};

function rootReducer(state = initialState, action) {
  return appReducer(state, action);
}

export default rootReducer;
