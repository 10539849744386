import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  Button,
  IconButton,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import "../Login/Login.css";
import OTPInput from "react-otp-input";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux-core/authentication/action";
import { useDispatch } from "react-redux";
import constants from "../../Maid-api/constants";
import packageJson from "../../../package.json";
import { X } from "lucide-react";
import { toast } from "react-toastify";
import { authService } from "../../Maid-api";
import CircularProgress from "@mui/material/CircularProgress";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const loginDispatch = (dispatch) => ({
  login: (phNo, mpin) => dispatch(login(phNo, mpin)),
});

const { VerifyOtp, CreateMpin, verifyUser } = authService;
function Login() {
  const { login } = loginDispatch(useDispatch());
  const [formData, setFormData] = useState({ mobile: "" });
  const [mpin, setMpin] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const [numbererrorMsg, setNumbererrorMsg] = useState(null);
  const [pinError, setPinError] = useState(false);
  const [pinErrorMessage, setPinErrorMessage] = useState();
  //use state for forgot pin
  const [updateforgotpin, setUpdateforgotpin] = useState(true);

  const [forgetOtp, setForgetOtp] = useState();
  const [forgetMpin, setForgetMpin] = useState();

  const [forgototperror, setForgototperror] = useState();
  const [forgotOtpErrorMessage, setForgotOtpErrorMessage] = useState();

  const [forgotMpinerror, setForgotMpinerror] = useState();
  const [forgotMpinerrorMessage, setForgotMpinerrorMessage] = useState();
  const [verifyData, setVerifyData] = useState();
  const [timeLeft, setTimeLeft] = useState(false); // Initial state set to null
  const [sendOtp, setSendOtp] = useState(true); // Initially, OTP can be sent
  //modal use state for fogot pin
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  console.log("forgetOtp", forgetOtp);


  //handles modal open
  const handleOpen = () => {
    setOpen(true);
  };
  // 1min otp again send time out
  const HandelAgainSendOtpTime = () => {
    setSendOtp(false); // Disable sending OTP
    setTimeLeft(true); 
  };

  
  // console.log("forgetOtp", forgetOtp);
  // handel forget password otp verify
  const HandelForgetOtpVerify = () => {
    if (forgetOtp?.length !== 4) {
      setForgototperror(true);
      setForgotOtpErrorMessage("Please enter a valid OTP");

      console.log("tst");
    } else {
      VerifyOtp(formData.mobile, verifyData?.verify_id, forgetOtp)
        .then((res) => {
          toast.success("Otp Verifyed !!");
          setForgototperror(false);
          setUpdateforgotpin(false);
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  // handel mpin create
  const HandelMpinCreate = () => {
    if (forgetMpin?.length !== 4) {
      setForgotMpinerror(true);
      setForgotMpinerrorMessage("Please Enter Yout MPin");

      console.log("test");
    } else {
      CreateMpin(forgetMpin)
        .then((res) => {
          toast.success(res.message);
          setForgotMpinerror(false);
          setOpen(false);
          setUpdateforgotpin(true);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  //handles modal close
  const handleClose = () => {
    setOpen(false);
    setForgetOtp();
  };

  const navigate = useNavigate();

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const HandelMpingive = (value) => {
    // console.log("value",value);
    setMpin(value);
    if (value.length == 4) {
      setPinError(false);
      setPinErrorMessage(" ");
    }
  };
  // const HandleForgotPin =() =>{

  // }

  //handles forgot pin button where it gives verification for user to verify and set mpin again
  const ForgotPin = ({ verifyData }) => {
    return (
      <div className="forgotpin-parent-container">
        {updateforgotpin ? (
          <div className="forgotpin-otp-verification-container">
            <h3>OTP verification</h3>
            <div className="forgot-otp-pin-modal-item">
              <OTPInput
                value={forgetOtp}
                onChange={setForgetOtp}
                numInputs={4}
                separator={<span> </span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    type="tel"
                    style={{
                      width: "37px",
                      height: "6vh",
                      fontSize: "16px",
                      textAlign: "center",
                      margin: "0 5px",
                      borderRadius: "4px",
                      border: "2px solid #ccc",

                      margin: "6px",
                      marginLeft: "7px",
                    }}
                    autoComplete="off"
                  />
                )}
              />

              <FormHelperText error={forgototperror}>
                {forgotOtpErrorMessage}
              </FormHelperText>
            </div>
            <Button onClick={() => HandelForgetOtpVerify()}>Submit</Button>
            <div style={{ textAlign: "center", marginTop: "1vh" }}>
              <p className="fortgot-modal-countdown-container">
                Resend Otp in{" "}
                <span className="forgot-modal-countdown-item">
                  <CountdownCircleTimer
                    isPlaying={timeLeft}
                    duration={60}
                    colors={["#FFFFFF"]}
                    colorsTime={[ 0]}
                    size={30}
                    onComplete={() => setSendOtp(true)}
                  >
                    {({ remainingTime }) => remainingTime}
                  </CountdownCircleTimer>
                </span>{" "}
              </p>

              {sendOtp && (
                <span style={{ color: "blue" }} onClick={handleSubmit}>
                  <u>Resend OTP</u>{" "}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="forgotpin-otp-verification-container">
            <h3>Set Your M-pin</h3>
            <div className="forgot-mpin-input-item">
              <OTPInput
                value={forgetMpin}
                onChange={setForgetMpin}
                numInputs={4}
                renderSeparator={<span> </span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    type="tel"
                    style={{
                      width: "39px",
                      height: "5.9vh",
                      fontSize: "16px",
                      textAlign: "center",
                      margin: "0 5px",
                      borderRadius: "4px",
                      border: "2px solid #ccc",
                      // borderTop: "none",
                      // borderRight: "none",
                      // borderLeft: "none",

                      marginLeft: "6px",
                      marginTop: "2vh",
                    }}
                  />
                )}
              />
              <FormHelperText error={forgotMpinerror}>
                {forgotMpinerrorMessage}
              </FormHelperText>
            </div>
            <Button onClick={HandelMpinCreate}>Submit</Button>
          </div>
        )}
      </div>
    );
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    // console.log("Mobile Number:", formData.mobile);
    // console.log("MPIN:", mpin);
    if (!formData.mobile) {
      setMobileError(true);
      setNumbererrorMsg("Please enter your mobile number");
    }
    if (mpin.length !== 4) {
      setPinError(true);
      setPinErrorMessage("Please enter a pin");
    } else {
      setPinError(false);
      setPinErrorMessage(" ");
      setIsLoading(true);
      login(formData.mobile, mpin)
        .then((res) => {
          if (res) {
            navigate("/");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err", err);
        });
    }
    //   navigate("/iden")
    // Perform any other actions like sending data to a server
  };
  useEffect(() => {
    if (formData.mobile && !/^[6-9]\d{9}$/.test(formData.mobile)) {
      setMobileError(true);
      setNumbererrorMsg("Please enter valid number");
    } else {
      setMobileError(false);
    }
  }, [formData.mobile]);

  // Handel login by Otp
  const HandelLoginByOtp = () => {
    console.log("formData", formData?.mobile);
    if (!formData?.mobile) {
      //console.log("tess");

      toast.warn("Please Enter Your Number First");
    } else {
      handleOpen();
      verifyUser(formData?.mobile)
        .then((res) => {
          console.log("data", res?.data);
          handleOpen();
          setVerifyData(res?.data);
          toast.success(res?.message);
          HandelAgainSendOtpTime();
        })
        .catch((err) => {
          console.log("err", err?.response?.data?.message);
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <div className="login-container">
        <div className="login-card">
          <div className="card-containerss">
            <img
              src="./images/splash.jpg"
              alt="background image"
              className="backgroud-image"
            />
          </div>
          <div className="card-containerr">
            <div className="container">
              <form onSubmit={handleSubmit}>
                <div>
                  <FormControl>
                    <TextField
                      name="mobile"
                      label="Mobile Number"
                      placeholder="Enter mobile number"
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      value={formData.mobile}
                      onChange={handleChange}
                      //  required
                      error={mobileError}
                      helperText={mobileError ? numbererrorMsg : ""}
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      InputProps={{
                        style: {
                          borderRadius: "5px", // Adjust the radius as needed
                          backgroundColor: "white",
                          height: "7vh",
                          // width: "90%",
                        },
                      }}
                    />
                    <FormHelperText />
                  </FormControl>
                </div>

                <div>
                  <label htmlFor="mpin" className="pin-text-style">
                    PIN:
                  </label>
                  <div className="error_align">
                    <OTPInput
                      value={mpin}
                      onChange={HandelMpingive}
                      numInputs={4}
                      renderSeparator={<span> </span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          type="tel"
                          style={{
                            width: "37.5px",
                            height: "6vh",
                            fontSize: "16px",
                            textAlign: "center",

                            borderRadius: "4px",
                            border: "2px solid #ccc",
                            // borderTop: "none",
                            // borderRight: "none",
                            // borderLeft: "none",
                            margin: "6px",
                            marginLeft: "6px",
                          }}
                        />
                      )}
                    />
                    <FormHelperText error={pinError}>
                      {pinErrorMessage}
                    </FormHelperText>
                  </div>
                </div>

                <div className="forgotpin-modal-container">
                  <Modal
                    open={open}
                    // onClose={handleClose}
                    sx={{
                      width: "100vw",
                      marginTop: "18vh",
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      marginTop={"5vh"}
                    >
                      <Grid item xs={3}>
                        <div
                          style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                            width: "89vw",
                            maxWidth: "400px",
                          }}
                        >
                          <IconButton
                            aria-label="close"
                            onClick={() => handleClose()}
                            style={{
                              left: "90%",
                            }}
                          >
                            <X />
                          </IconButton>

                          <ForgotPin verifyData={verifyData} />
                        </div>
                      </Grid>
                    </Grid>
                  </Modal>
                </div>

                <div className="login-otp-text" onClick={HandelLoginByOtp}>
                  Forgot PIN ?
                </div>
                <div className="button-container">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="custom-button"
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "Sign in"
                    )}
                  </Button>
                </div>
              </form>
              <div
                style={{
                  color: "black",
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                <label className="text-style"> Not a member ?</label>{" "}
                <Link
                  to="/ragistration"
                  style={{ textDecoration: "none", color: "#1a434e" }}
                  className="text-style"
                >
                  Sign Up
                </Link>
              </div>
              <div
                style={{
                  color: "blue",
                  textAlign: "center",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div style={{ fontSize: "0.7rem" }}>
            V {packageJson.version} ©2024
          </div>
          <div style={{ fontSize: "0.6rem" }}>
            Powered by: SamMegh Technology
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
