import React from "react";
import "./Success.css";
import { Check } from "lucide-react";
import { Button } from "@mui/material";

const Success = (props) => {
  // const [viewopen, setViewopen] = useState(false);

  // function View() {
  //   return (
  //     <div className="view-item-parent-container">
  //       <h3
  //         style={{
  //           textAlign: "center",
  //           marginTop: "1vh",
  //           fontWeight: "600",
  //           color: "#1a434e",
  //         }}
  //       >
  //         Assigned Staff
  //       </h3>
  //       <div className="view-item-service-and-image-container">
  //         <div
  //           style={{
  //             marginTop: "1vh",
  //             marginLeft: "8vw",
  //             position: "relative",
  //           }}
  //         >
  //           <CardMedia
  //             component="img"
  //             image="./images/sammeghlogo.png"
  //             alt="alt-text"
  //             sx={{
  //               height: "7.9vh",
  //               width: "16.1vw",

  //               marginBottom: "1vh",
  //             }}
  //             style={{
  //               marginTop: "0.9vh",
  //             }}
  //           />

  //           <div className="star-container">
  //             <span> 4.8</span>
  //             <span>
  //               <CardMedia
  //                 component="img"
  //                 image="./images/star.png"
  //                 alt="alt-text"
  //                 sx={{ height: "1.9vh", width: "3.6vw", marginTop: "0.1vh" }}
  //               />
  //             </span>
  //           </div>
  //         </div>
  //         <div className="service-view-container">
  //           <span>Mopping </span>
  //           <span>Selected Service </span>
  //         </div>
  //       </div>
  //       <div className=" view-item-information-container">
  //         <div className="view-item-maid-information">
  //           <div>
  //             Name :<span>Jeevan</span>
  //           </div>
  //           <div>
  //             Phone :<span>805077XXXX</span>
  //           </div>
  //         </div>
  //         <div className="service-completed-item ">
  //           <span style={{ fontSize: "1.2rem" }}>20+</span>
  //           <span> service completed </span>
  //         </div>
  //       </div>
  //       <div className="total-service-amount">
  //         <span style={{ marginLeft: "2vw" }}>Total Service Amount :</span>
  //         <div style={{ display: "flex", marginRight: "18vw" }}>
  //           <span style={{ marginTop: "0.2vh" }}>
  //             <IndianRupee size={19} />
  //           </span>
  //           <span>2000</span>
  //         </div>
  //       </div>
  //       <div className="view-item-paynow-btn">
  //         <Button>Pay Now</Button>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="parent-container-of-success-page">
      <div>
        <div className="check-mark-container">
          <Check
            color="#1a434e"
            style={{ marginLeft: "11.8vw", marginTop: "5vh" }}
            size={110}
          />
        </div>
      </div>
      <div className="succes-page-text-container">
        <span style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
          Confirmed
        </span>
        <span style={{ width: "78vw", marginLeft: "9.9vw" }}>
          Your order is placed successfully, please check your dashboard.
        </span>
      </div>
      <div className="success-page-continue-btn-container">
        <Button onClick={() => props.back()}>Continue</Button>
      </div>
    </div>
  );
};

export default Success;
