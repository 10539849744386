import React from "react";
import "./Decision.css";
import { ArrowLeft } from "lucide-react";
import CardMedia from "@mui/material/CardMedia";
import { useState } from "react";

const Decision = (props) => {
  //use state to update the date to 4 when right arrow button clicked
  const [updateleftarrowdata, setUpdateleftarrowdata] = useState(1);
  const [updatepagedecision, setUpdatepagedecision] = useState(5);
  //onclick funtion for left arrow button
  function handlesubmit() {
    setUpdateleftarrowdata(1);
    props.update(updateleftarrowdata);
  }

  //this function is used to update the page decision
  function Pagedecision() {
    props.decision(updatepagedecision);
    props.sendDision('urgent')
  }

  function Pagedecision2 (){
    props.decision(updatepagedecision);
    props.sendDision('noturgent');
  }
  return (
    <div className="parent-conatiner-of-decision">
      <div className="top-text-container">
        <button className="leftarrow-btn" onClick={handlesubmit}>
          <ArrowLeft
            color="white"
            size={21}
            style={{
              marginTop: "0vh",
              marginLeft: "0vw",
              paddingTop: "0.7vh",
            }}
          />
        </button>
        <span style={{ paddingTop: "1vh", fontWeight: "500" }}>
          How urgent is your requirements?
        </span>
      </div>
      <div className="parent-requirements-items">
        <div className="requirement-item">
          <div className="icon-container">
            {" "}
            <CardMedia
              component="img"
              image="./images/calendarr.png"
              alt="alt-text"
              style={{
                height: "4.1vh",
                width: "8.4vw",
                marginTop: "2.6vh",
                marginLeft: "1.3vw",
              }}
            />
          </div>
          <div className="requirement-item-text-container">
            <div className="requirement-item-1sttext" onClick={Pagedecision}>
              {" "}
              Urgent requirement
            </div>
            <div className="requirement-item-2ndtext">
              {" "}
              we will contact you as soon as possible
            </div>
          </div>
        </div>
        <div className="requirement-item">
          <div className="icon-container">
            {" "}
            <CardMedia
              component="img"
              image="./images/calendarr.png"
              alt="alt-text"
              style={{
                height: "4.1vh",
                width: "8.4vw",
                marginTop: "2.6vh",
                marginLeft: "1.3vw",
              }}
            />
          </div>
          <div className="requirement-item-text-container">
            <div className="requirement-item-1sttext" onClick={Pagedecision2}>
              {" "}
              Not so urgent requirement
            </div>
            <div className="requirement-item-2ndtext">
              {" "}
              we will contact you within 7 working days
            </div>
          </div>
        </div>
        {/* <div className="requirement-item">
          <div className="icon-container">
            {" "}
            <CardMedia
              component="img"
              image="./images/searchh.png"
              alt="alt-text"
              style={{
                height: "3.7vh",
                width: "7.6vw",
                marginTop: "2.8vh",
                marginLeft: "1.6vw",
              }}
            />
          </div> */}
        {/* <div className="requirement-item-text-container">
            <div className="requirement-item-1sttext">
              {" "}
              Just checking the site
            </div>
            <div className="requirement-item-2ndtext">
              {" "}
              we will try to contact you
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Decision;
