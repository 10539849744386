import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Profile from "../profile/Profile";

// import MailIcon from "@mui/icons-material/Mail";
import CardMedia from "@mui/material/CardMedia";
import "./DesktopNavigation.css";
import CssBaseline from "@mui/material/CssBaseline";
// import Profile from "../profile/Profile";
import { useNavigate } from "react-router-dom";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  // interface AppBarProps extends MuiAppBarProps {
  //   open?: boolean;
  // }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  const text = ["Dashboard", "Service", "Profile"];
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  //to navigates around pages in navigation bar page use state
  const [navpage, setNavpage] = useState(0);

  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <div className="desktop-navigation-page-parent-container">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar sx={{ backgroundColor: "#d2d3d6" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpen(!open)}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open} color="blue">
            <DrawerHeader>
              <IconButton
                onClick={() => {
                  handleDrawerClose();
                  setOpen(!open);
                }}
              >
                {theme.direction === "rtl" ? (
                  <MenuIcon />
                ) : (
                  <MenuIcon fontSize="large" style={{ color: "white" }} />
                )}
                {/* {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )} */}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem
                disablePadding
                sx={{
                  backgroundColor: activeIndex === 0 ? "#607274" : undefined,
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#607274",
                  },
                }}
                onClick={() => {
                  setActiveIndex(0);
                  setNavpage(0);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <DashboardIcon fontSize="large" style={{ color: "white" }} /> */}
                    <CardMedia
                      component="img"
                      height="25"
                      image="./images/dashboard.png"
                      alt="alt-text"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={text[0]}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  backgroundColor: activeIndex === 1 ? "#607274" : undefined,
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#607274",
                  },
                }}
                onClick={() => {
                  setActiveIndex(1);
                  setNavpage(1);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {/* <DashboardIcon fontSize="large" style={{ color: "white" }} /> */}
                    <CardMedia
                      component="img"
                      height="26"
                      image="./images/service.png"
                      alt="alt-text"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={text[1]}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                sx={{
                  backgroundColor: activeIndex === 2 ? "#607274" : undefined,
                  display: "block",
                  "&:hover": {
                    backgroundColor: "#607274",
                  },
                }}
                onClick={() => {
                  setActiveIndex(2);
                  setNavpage(2);
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="26"
                      image="./images/user.png"
                      alt="alt-text"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={text[2]}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
            {/* <Divider /> */}
          </Drawer>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              border: "1px solid red",
              height: "91vh",
              width: "100vw",
              marginTop: "6vh",
              marginLeft: "-1vw",
            }}
          >
            {navpage === 2 ? <Profile></Profile> : null}
          </Box>
        </Box>
      </div>
    </>
  );
}
