import React, { useState, useEffect } from "react";
import "./Service.css";
import CardMedia from "@mui/material/CardMedia";
// import { Bell } from "lucide-react";
import ServiceCard from "../service-card/ServiceCard";
import { ArrowRight } from "lucide-react";
import { servicesapi } from "../../Maid-api";
import { toast } from "react-toastify";

const { getAllServices } = servicesapi;

const Service = (props) => {
  const [allService, setAllService] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  //use state to update the date to 4 when right arrow button clicked
  const [updatedata, setUpdatedata] = useState(4);

  // services api triger
  useEffect(() => {
    getAllServices()
      .then((res) => {
        console.log("get data servies", res?.data);
        setAllService(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  //slide image array holder for to hold images

  const counter = [
    {
      images: "./images/add1.png",
      title: "red",
    },
    {
      images: "./images/add2.png",
      title: "red",
    },
    {
      images: "./images/add3.png",
      title: "red",
    },
    {
      images: "./images/add4.png",
      title: "red",
    },
  ];

  //image carsouel use state

  let timeOut = null;

  //image carsouel useffect it is used for autoplay after every 3s it will chnage the image
  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 3000);
  });

  //image carsouel right side move funtion
  const slideRight = () => {
    setCurrent(current === counter.length - 1 ? 0 : current + 1);
  };

  //onclick funtion for right arrow button
  function handlesubmit() {
    if (selectedServiceId) {
      setUpdatedata(4);
      props.update(updatedata);
    } else {
      toast.warn("Please select service");
    }
  }

  const handleCardClick = (id) => {
    if (selectedServiceId === id) {
      setSelectedServiceId(null);
    } else {
      setSelectedServiceId(id);
      props.handleServiceSelect(id);
    }

    // if (id) {
    //   console.log("Selected service ID:", id);
    // }
  };

  return (
    <div className="parent-container-of-service">
      <div className="parent-container-of-service-items">
        <div className="slide-image-container">
          <div
            className="carousel"
            onMouseEnter={() => {
              setAutoPlay(false);
              clearTimeout(timeOut);
            }}
            onMouseLeave={() => {
              setAutoPlay(true);
            }}
          >
            <div className="carousel_wrapper">
              {counter.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={
                      index === current
                        ? "carousel_card carousel_card-active"
                        : "carousel_card"
                    }
                  >
                    <img
                      className="card-image"
                      src={image.images}
                      alt="imagesss "
                    />
                    <div className="card_overlay"></div>
                  </div>
                );
              })}
              <div className="carousel_pagination">
                {counter.map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        index === current
                          ? "pagination_dot pagination_dot-active"
                          : "pagination_dot"
                      }
                    ></div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="itext-item-container">Select Services</div>
        <div className="services-item-container">
          <div style={{display:'flex',justifyContent:'center'}}>
          <div className="card-container-service-card">
            {allService?.map((item, index) => (
              <ServiceCard
                key={item._id}
                item={item}
                isSelected={selectedServiceId === item._id}
                handleCardClick={handleCardClick}
              />
            ))}
          </div>
          </div>
         

          <div className="continue-rightarrow-button">
            <button className="rightarrow-btn" onClick={handlesubmit}>
              <ArrowRight
                color="white"
                size={24}
                style={{
                  marginTop: "0vh",
                  marginLeft: "0vw",
                  paddingTop: "0.7vh",
                }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
