import "./App.css";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Navigation from "../navigationbar/Navigation";
import MiniDrawer from "../desktopnavigationsidebar/DesktopNavigation";
import Profile from "../profile/Profile";
// import Admin from "../Admin/Admin";
// import MaidMaster from "../MaidMaster/MaidMaster";
import Dashboard from "../dashboard/Dashboard";
import Service from "../service/Service";
import Iden from "../navigationbar/Iden";
import Registration from "../Registration/Ragistration";
import Splash from "../splashpage/Splash";
import Login from "../Login/Login";
import DasboardCard from "../dashboard-card/DasboardCard";
import ServiceCard from "../service-card/ServiceCard";
import Decision from "../decisionpage/Decision";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { makeSelectLoginDetail } from "../../redux-core/authentication/selectors";
import { useSelector } from "react-redux";
import { authService } from "../../Maid-api";
import Success from "../success-page/Success";
import { Box, Button, Modal, Typography } from "@mui/material";
import { usePWAInstall } from 'react-use-pwa-install';
import withClearCache from "../../ClearCache";

const ClearCacheComponent = withClearCache(MainApp);

const stateSelector = createSelector(makeSelectLoginDetail, (loginDetails) => ({
  loginDetails,
}));

function App() {
  return (
    <Router>
      <ClearCacheComponent />
    </Router>
  );
}

function MainApp() {
  const [loggedIn, setLoggedIn] = useState();
  const { loginDetails } = useSelector(stateSelector);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setLoggedIn(localStorage.getItem("userToken"));
  }, [loginDetails]);

  if (localStorage.getItem("userToken")) {
    authService.setAuthHeader(localStorage.getItem("userToken"));
  }

  const handleLogout = () => {
    setLoggedIn(localStorage.getItem("userToken"));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const install = usePWAInstall();

  useEffect(() => {
    const handleBackButton = (event) => {
      if (location.pathname === "/" ) {
        event.preventDefault();
        if (window.navigator && window.navigator.app) {
          window.navigator.app.exitApp();
        } else {
          window.history.pushState(null, null, location.href);
          window.addEventListener('popstate', () => {
            window.history.pushState(null, null, location.href);
          });
        }
      } else {
        navigate(-1);
      }
    };

    window.history.pushState(null, null, location.href);
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [location, navigate]);

  return (
    <>
      <div>
        {install && (
          <Modal
            open={isModalOpen}
            onClose={handleCancel}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '95vw',
              paddingLeft: '2.5vw',
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: 'white',
                padding: 2,
                borderRadius: 2,
                boxShadow: 24,
              }}
            >
              <Box sx={{ display: 'flex', gap: '0.5vw' }}>
                <img src='./images/app-icon.png' alt="app-icon" height="40" width="40" />
                <Typography id="modal-modal-title" variant="h8" component="h8" fontSize="0.8em" marginTop="1.4vh">
                  Add QuickJi Application to Home Screen?
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={install}>Add</Button>
              </Box>
            </Box>
          </Modal>
        )}

        <Routes>
          {loggedIn ? (
            <>
              <Route path="/ragistration" element={<Registration />} />
              <Route path="/decision" element={<Decision />} />
              <Route path="/card" element={<ServiceCard />} />
              <Route path="/card" element={<DasboardCard />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/service" element={<Service />} />
              <Route path="/mobilenav" element={<Navigation />} />
              <Route path="/desktopnav" element={<MiniDrawer />} />
              <Route path="/" element={<Iden HandelLogoutTrigger={handleLogout} />} />
              <Route path="/success" element={<Success />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Splash />} />
              <Route path="/login" element={<Login />} />
              <Route path="/ragistration" element={<Registration />} />
            </>
          )}
        </Routes>
      </div>
    </>
  );
}

export default App;
