import axios from "axios";
import constants from "./constants";
import store from "../store";
var fileDownload = require('js-file-download');
axios.defaults.baseURL = constants.API_URL;

const instance = axios.create({});
instance.interceptors.request.use(async (config) => {
  const value = localStorage.getItem("userToken");
  config.headers = {
    Authorization: `Bearer ${value}`,
    Accept: "application/json",
  };
  store.dispatch({ type: "spin", payload: true });
  return config;
});

instance.interceptors.response.use(
    function (response) {
      store.dispatch({ type: "spin", payload: false });
      return response;
    },
    function (error) {
      const res = error.response;
      store.dispatch({ type: "spin", payload: false });
  
      if (res.status === 401) {
        window.localStorage.removeItem("persist:root");
        window.localStorage.removeItem("userToken");
        window.localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  const setAuthHeader = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.defaults.withCredentials = true;
  };


  // send notification messgage order place
  const smsForOrderPlaced =async(data) => {
    try{
        const response= await instance.post("api/order-placed-notification",data,
        {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods": "POST",
            },

        }
      );
      return response.data;

    }catch(error){
        throw error;
    }
  }

  // send notification message payment confirmation

  const smsForPaymentConfirmation =async(data) => {
    try{
        const response= await instance.post("api/order-payment-confirmation",data,
        {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods": "POST",
            },

        }
      );
      return response.data;

    }catch(error){
        throw error;
    }
  }


  export default {
    smsForOrderPlaced,
    smsForPaymentConfirmation,
  }