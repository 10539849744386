import React, { useState } from "react";
import { Button, CardMedia, Divider, TextField } from "@mui/material";
import "./DasboardCard.css";
import {
  Download,
  Expand,
  IndianRupee,
  Info,
  MessageSquareShare,
  PhoneForwarded,
  ShieldX,
  Star,
  X,
} from "lucide-react";
import Sheet from "react-modal-sheet";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { userOrderapi } from "../../Maid-api";
import { toast } from "react-toastify";
import { ChevronDown } from "lucide-react";
import { color } from "framer-motion";
import { notificationApi } from "../../Maid-api";
import { makeSelectLoginDetail } from "../../redux-core/authentication/selectors";
import { useSelector } from "react-redux";

const {smsForPaymentConfirmation}=notificationApi;

const {
  newUserOrder,
  terminateuserOrder,
  staffFeedback,
  createOrder,
  verifyPayment,
  getCostmerFeedback,
  getInvoiceDownload,
} = userOrderapi;
const DasboardCard = (props) => {
   console.log("props",props);
  // console.log("props",props?.items?.service_id);
  const loginDetails=useSelector(makeSelectLoginDetail)
  const keyy = "";
  const [checked, setChecked] = useState(true);
  const handleChange = (e) => {
    setChecked(e.target.checked);
  };
  const [value, setValue] = React.useState("");
  //use state for extend
  const [isOpen, setOpen] = useState(false);
  //usestate for terminate
  const [isOpenterminate, setOpenterminate] = useState(false);
  const [isOpenfeedback, setOpenfeedback] = useState(false);
  const [selectExtendItem, setSelectExtendItem] = useState(null);
  const [selectTerminateItem, setSelectTerminateItem] = useState(null);
  const [selectFeedbackItem, setSelectFeedbackItem] = useState(null);
  const [extendDate, setExtendDate] = useState(null);
  const [orderData, setOrderData] = useState();
  const [getFeedbackData, setGetFeedBackData] = useState();
  const [extendDateError, setExtendDateError] = useState(false);
  const [extendDateErrorMessage, setExtendDateErrorMessage] = useState("");

  //view use state update
  const [viewopen, setViewopen] = useState(false);
  // const [key, setKey] = useState(1);
  // const [value, setValue] = useState(dayjs("2022-04-17"));

  // Handel click payemnt
  const HandelPayment = (value) => {
    console.log("HandelPayment value:", value);
    const data = {
      amount: value?.amount_billed,
    };

    createOrder(data)
      .then((res) => {
        console.log("createOrder response:", res);
        verifyData(res?.data, value);
      })
      .catch((err) => {
        console.log("createOrder error:", err);
      });
  };

  const loadScript = (src) =>
    new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        console.log("razorpay loaded successfully");
        resolve(true);
      };
      script.onerror = () => {
        console.log("error in loading razorpay");
        resolve(false);
      };
      document.body.appendChild(script);
    });

  const verifyData = async (data, value) => {
    console.log("verifyData data:", data);
    console.log("verifyData value:", value);
    const options = {
      key: "rzp_test_Zb6Tf4ECRJmMBm",
      amount: data.amount,
      currency: data.currency,
      name: "SamMegh Thchnology",
      description: "Test Mode",
      order_id: data.id,
      handler: async (response) => {
        console.log("handler response:", response);
        const Data = {
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          order_id: value?._id,
          amount_billed: data.amount,
          amount_paid: data.amount,
        };
        console.log("handler Data:", Data);
        try {
          await verifyPayment(Data).then((res)=>{
            console.log("verifyPayment response:", res?.paymentOrder?.order_number);
            smsForPaymentConfirmation({order_number:res?.paymentOrder?.order_number,mobile_number:loginDetails?.mobile_number})
          })
        
          props.handlePendingOrder();
          props.handleOngoingOrder();
        } catch (err) {
          console.log("verifyPayment error:", err);
        }
      },
      theme: {
        color: "#1a434e",
      },
    };

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // Assuming createOrder and verifyPayment are defined elsewhere in the code

  // extend slide open
  const OnclickExtend = (data) => {
    console.log(data);
    setOrderData(data);
    setSelectExtendItem("Extend");
    setOpen(true);
  };

  // terminate slide open
  const OnclickTerminate = (Data) => {
    setSelectTerminateItem("Terminate");
    setOrderData(Data);
    setOpenterminate(true);
  };

  // feedback slide open
  const openFeedback = (Data) => {
    console.log("Data}}}", Data?._id);
    getCostmerFeedback(Data?._id)
      .then((res) => {
        console.log("res___", res?.feedback);
        setGetFeedBackData(res?.feedback);
      })
      .catch((error) => {
        console.log("error__", error);
      });
    setOpenfeedback(true);
    setSelectFeedbackItem("Feedback");
    setOrderData(Data);
  };

  // handel download invoice
  const HandelDelect = (id) => {
    console.log("_id ::: ", id);
    getInvoiceDownload(id)
      .then((res) => {
        console.log("test");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleTextChange = (e) => {
    setValue(e.target.value);
  };

  // funtion for extend button
  

function Extend(props) {
 

  console.log("props", props.orderData);
  console.log("extendDate", dayjs(extendDate).format("YYYY-MM-DD"));
  console.log("toDate", props.orderData.to_date);
  console.log("extendDate", new Date(extendDate));

  // Convert the order's to_date to the desired format
  const todate = new Date(props.orderData.to_date);

 
  const HandelSelectExtendDate = (event) => {
    const date = new Date(event.target.value);
 

    console.log(date);
    console.log(todate); 

    if (date < todate) {
      setExtendDateError(true);
      setExtendDateErrorMessage("Extend Date Is Greater Than To Last Service Date");
    } else {
      setExtendDate(event.target.value);
      setExtendDateError(false);
    }
  };

  const HandelExtendDate = () => {
    if (!extendDate) {
      setExtendDateError(true);
      setExtendDateErrorMessage("Please Select Extend Date");
    } else {
      const toDate = new Date(props.orderData.to_date);

      // Add one day to the toDate
      toDate.setDate(toDate.getDate() + 1);

      // Get the year, month, and day parts
      const year = toDate.getFullYear();
      const month = String(toDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
      const day = String(toDate.getDate()).padStart(2, "0");

      // Form the YYYY-MM-DD format string
      const fromDate = `${year}-${month}-${day}`;
      console.log("fromDate", fromDate);

      const Data = {
        service_id: props.orderData.service_id,
        address_id: props.orderData.address_id,
        requirement_type: "extend",
        num_of_member: props.orderData.num_of_member,
        house_type: props.orderData.house_type,
        start_time: props.orderData.start_time,
        from_date: fromDate,
        to_date: extendDate,
        min_range: props.orderData.min_range,
        max_range: props.orderData.max_range,
      };
      
      newUserOrder(Data)
        .then((res) => {
          console.log("res", res);
          toast.success(res?.message);
          props.extendClose();
          props.handlePendingOrder();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
console.log("extendDateError",extendDateError);
  return (
    <div className="extend-items-container">
      <h2 style={{ color: "#1a434e" }}>Extend Your Service</h2>
      {/* <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DatePicker
          label="Extend Date"
          onChange={HandelSelectExtendDate}
          value={extendDate}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider> */}
      <TextField
      type="date"
        label="Extend Date"
        onChange={HandelSelectExtendDate}
        value={extendDate}
        format="DD/MM/YYYY"
      />
      {extendDateError && <p style={{ color: 'red',fontSize:'0.7em' }}>{extendDateErrorMessage}</p>}
      <Button onClick={HandelExtendDate}>Submit</Button>
    </div>
  );
}




  //ustestate and handle submit button used inside termin ate function
  const [text, setText] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Inputed data: ", text);
  };

  //function for terminate button
  function Terminate(props) {
    const [reasons, setReasons] = useState({
      irregularWork: false,
      notSatisfied: false,
      misbehaviour: false,
    });
    const [text, setText] = useState("");
    console.log("orderData", props?.orderData?._id);

    const handleChange = (event) => {
      setReasons({ ...reasons, [event.target.name]: event.target.checked });
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      console.log("Reasons for termination:");
      console.log(reasons);
      console.log("Additional reason:", text);
      const Data = {
        is_cancelled: true,
        is_completed: true,
        on_going: false,
        terminate_feedback: [
          {
            irregularWork: reasons.irregularWork ? "Irregular in work" : "",
            notSatisfied: reasons.notSatisfied ? "Not satisfied with work" : "",
            misbehaviour: reasons.misbehaviour ? "Misbehaviour" : "",
            feedBackText: text,
          },
        ],
      };
     
      terminateuserOrder(props?.orderData?._id, Data)
        .then((res) => {
          console.log("res", res);
          toast.success(res?.message);
          props.terminateClose();
          props.handleOngoingOrder();
          props.handelCompletedOrder();
        })
        .catch((err) => {});
      console.log("Data", Data);
    };

    return (
      <div className="terminate-items-container">
        <h2
          style={{ marginLeft: "16vw", color: "#1a434e", marginBottom: "1vh" }}
        >
          Terminate The Service
        </h2>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={reasons.irregularWork}
                  onChange={handleChange}
                  name="irregularWork"
                />
              }
              label="Irregular in work"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={reasons.notSatisfied}
                  onChange={handleChange}
                  name="notSatisfied"
                />
              }
              label="Not satisfied with work"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={reasons.misbehaviour}
                  onChange={handleChange}
                  name="misbehaviour"
                />
              }
              label="Misbehaviour"
            />
            <TextField
              id="outlined-multiline-static"
              label="Reason To Terminate"
              multiline
              rows={3}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <div className="terminate-submit-btn">
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </div>
          </FormGroup>
        </form>
      </div>
    );
  }

  function Feedback(props) {
    const [selectedStars, setSelectedStars] = useState(
      getFeedbackData?.rating ? getFeedbackData?.rating : 0
    );
    const [textAreaData, setTextAreaData] = useState("");
    console.log("props", props);

    const handleStarClick = (e, index) => {
      setSelectedStars(index + 1);
    };

    const handleSubmit = () => {
      console.log("User rated us: ", selectedStars, textAreaData);
      const Data = {
        order_id: props?.orderData?._id,
        maid_id: props?.orderData?.maid_id, // will change when maid id comes in user order
        service_id: props?.orderData?.service_id,
        rating: selectedStars,
        feedback: textAreaData,
      };
      staffFeedback(Data)
        .then((res) => {
          toast.success(res?.message);
          props?.closeFeedback();
        })
        .catch((err) => {
          console.log("err", err);
        });
    };
    const handleTextAreaChange = (e) => {
      setTextAreaData(e.target.value);
    };

    return (
      <div className="feedback-form-container">
        <h2 style={{ color: "#1a434e" }}>Your Feedback</h2>
        <p style={{ fontSize: '0.7em' }}>
  Your Valuable Feedback For <span style={{ color: "blue" }}><u>{props?.orderData?.maid_info?.staff_name}</u></span>
</p>

        <FormGroup>
          <div
            style={{
              display: "flex",
              gap: "2vw",
              paddingLeft: "1vw",
              marginLeft: "13vw",
              marginTop: "3vh",
              fontSize: "1.2rem",
            }}
          >
            <span style={{ paddingRight: "15vw" }}>
              {" "}
                    {" "}
            </span>
            {Array.from({ length: 5 }, (_, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Star
                    strokeWidth={4}
                    color={selectedStars > i ? "#1a434e " : "#E2DFD0"}
                    onClick={
                      getFeedbackData ? null : (e) => handleStarClick(e, i)
                    }
                  />
                }
              />
            ))}
          </div>
          <TextField
            label="Feedback"
            multiline
            rows={3}
            disabled={getFeedbackData ? true : false}
            value={
              getFeedbackData?.feedback
                ? getFeedbackData?.feedback
                : textAreaData
            }
            onChange={handleTextAreaChange}
          />
        </FormGroup>
        {!getFeedbackData ? (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        ) : (
          <div style={{ marginBottom: "20px" }}>{""}</div>
        )}
      </div>
    );
  }

  // view funtion handles shows the maid details
  function View(props) {
    console.log("props>>>>", props);
    // Helper function to mask the mobile number
    const maskMobileNumber = (number) => {
      if (!number) return "";
      const maskedPart = "XXXX";
      const visiblePart = number.slice(0, -4);
      return `${visiblePart}${maskedPart}`;
    };

    // Component to display the masked mobile number
    const MobileNumberDisplay = ({ mobileNumber }) => {
      const maskedNumber = maskMobileNumber(mobileNumber);
      return <span>{maskedNumber}</span>;
    };

    return (
      <div className="view-item-parent-container">
        <h3
          style={{
            textAlign: "center",
            marginTop: "1vh",
            fontWeight: "600",
            color: "#1a434e",
          }}
        >
          Assigned Staff
        </h3>
        <div className="view-item-service-and-image-container">
          <div
            style={{
              marginTop: "1vh",
              marginLeft: "8vw",
              position: "relative",
            }}
          >
            <CardMedia
              component="img"
              image={props?.data?.maid_info?.photo}
              alt="photo"
              sx={{
                height: "7.9vh",
                width: "16.1vw",
                borderRadius: "50%",

                marginBottom: "1vh",
              }}
              style={{
                marginTop: "0.8vh",
              }}
            />
            {/* 
            <div className="star-container">
              <span> 4.8</span>
              <span>
                <CardMedia
                  component="img"
                  image="./images/star.png"
                  alt="alt-text"
                  sx={{ height: "1.9vh", width: "3.6vw", marginTop: "0.1vh" }}
                />
              </span>
            </div> */}
          </div>
          <div className="service-view-container">
            <span>{props?.data?.service_info?.service_name} </span>
            <span></span>
          </div>
        </div>
        <div className=" view-item-information-container">
          <div className="view-item-maid-information">
            <div>
              Name :<span>{props?.data?.maid_info?.staff_name}</span>
            </div>
            <div>
              Phone:{" "}
              <MobileNumberDisplay
                mobileNumber={props?.data?.maid_info?.mobile_number}
              />
            </div>
          </div>
          <div className="service-completed-item ">
            <span style={{ fontSize: "1.2rem" }}>
              {props?.data?.maid_info?.order_count}+
            </span>
            <span> service completed </span>
          </div>
        </div>
        <div className="convience-fee-container">
          <div className="convience-fee-card">
            <div className="convience-fee-margin">
              <div className="fee-content">
                <div className="price-fare-name">Service Fare</div>
                <div className="price-fare">
                  {" "}
                  <IndianRupee size={10} />
                  <span>{props?.data?.maid_price}</span>
                </div>
              </div>
              <div className="fee-content">
                <div className="price-fare-name">
                  <span>Convience fee</span>{" "}
                  <span>
                    <ChevronDown size={15} />{" "}
                  </span>
                </div>
                <div className="price-fare">
                  {" "}
                  <IndianRupee size={10} />
                  <span>{props?.data?.convience_value}</span>
                </div>
              </div>
              <hr />
             
              <div className="fee-content">
                <div className="price-fare-name">Base Price</div>
                <div className="price-fare">
                  {" "}
                  <IndianRupee size={10} />{" "}
                  <span>{props?.data?.taxable_amount}</span>
                </div>
              </div>

              <div className="fee-content">
                <div className="price-fare-name">Gst (18%)</div>
                <div className="price-fare">
                  {" "}
                  <IndianRupee size={10} />{" "}
                  <span>{props?.data?.gst_amount}</span>
                </div>
              </div>
              <hr />
              <div className="fee-content">
                <div className="price-fare-name">Total Order Amount :</div>
                <div className="price-fare">
                  {" "}
                  <IndianRupee size={10} />{" "}
                  <span>{props?.data?.amount_billed}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="total-service-amount">
          <span style={{ marginLeft: "2vw" }}>Total Service Amount :</span>
          <div style={{ display: "flex", marginRight: "18vw" }}>
            <span style={{ marginTop: "0.2vh" }}>
              <IndianRupee size={19} />
            </span>
            <span>{props?.data?.amount_billed}</span>
          </div>
        </div> */}
        {/* <div className="view-item-paynow-btn">
          <Button>Pay Now</Button>
        </div> */}
      </div>
    );
  }

  // handel Extend Close
  const extendClose = () => {
    setOpen(false);
  };
  // handel terminate close
  const terminateClose = () => {
    setOpenterminate(false);
  };

  // handel feedback close
  const feedbackClose = () => {
    setOpenfeedback(false);
  };
  const detail = [];

  // handel for onclick for calling
  const handleClick = () => {
    const mobileNumber = props?.items?.maid_info?.mobile_number;
    console.log(mobileNumber);
    window.location.href = `tel:${mobileNumber}`;
  };

  return (
    <div>
      <div className="pending-parent-card-container">
        <div className="card-container-title-items">
          <div className="card-title-container">
            {props?.items?.service_info?.service_name}
          </div>
          <div className="card-image-container">
            {" "}
            <CardMedia
              component="img"
              image={props?.items?.service_info?.service_icon}
              alt="alt-text"
              sx={{
                height: "3.6vh",
                width: "7vw",
                marginLeft: "2.9vw",
                marginBottom: "1vh",
              }}
              style={{
                marginTop: "0.9vh",
              }}
            />{" "}
          </div>
        </div>
        <div className="card-date-status-container">
          <div className="card-date-item-container">
            <div>
              StartDate: {dayjs(props?.items?.from_date).format("DD-MM-YYYY")}
            </div>

            <div>
              EndDate : {dayjs(props?.items?.to_date).format("DD-MM-YYYY")}
            </div>
          </div>

          {props?.items?.is_completed === true ? (
            <div
              className="status-completed-item"
              style={{
                color: props?.items?.is_cancelled === true ? "red" : "green",
              }}
            >
              {props?.items?.is_cancelled === true ? "Terminate" : "Completed"}
            </div>
          ) : (
            <div
              className="status-item"
              style={{
                color: props?.items?.is_approved === true ? "green" : "red",
              }}
            >
              {" "}
              {props?.items?.is_approved === true ? "Approved" : "Pending"}
            </div>
          )}
        </div>
        <hr style={{ width: "84vw", marginLeft: "4vw" }} />

        {props?.items?.is_approved === true &&
        props?.items?.on_going === false &&
        props?.items?.is_completed === false ? (
          <div className="card-other-item-container">
            <div>
              <Button
                style={{ paddingTop: "1.1vh" }}
                onClick={() => setViewopen(true)}
              >
                <Info size={17} style={{ marginRight: "1px" }} />
                View
              </Button>
              <Sheet isOpen={viewopen} onClose={() => setViewopen(false)}>
                <Sheet.Container>
                  <Sheet.Header />
                  <Sheet.Content>{<View data={props?.items} />}</Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
              </Sheet>
            </div>

            <Button
              style={{ paddingTop: "1.1vh" }}
              onClick={() => HandelPayment(props?.items)}
            >
              <IndianRupee size={17} style={{ marginTop: "1px" }} />
              PayNow
            </Button>
          </div>
        ) : (
          <></>
        )}

        {props?.items?.on_going === true ? (
          <div className="card-other-item-container">
            <Button style={{ paddingTop: "0.9vh" }} onClick={handleClick}>
              {" "}
              <PhoneForwarded size={18} style={{ marginRight: "2px" }} />
              Call
            </Button>

            <div>
              <Button
                style={{ paddingTop: "1.1vh" }}
                onClick={() => OnclickExtend(props?.items)}
              >
                {" "}
                <Expand size={18} style={{ marginRight: "1px" }} />
                Extend
              </Button>
              {selectExtendItem === "Extend" ? (
                <Sheet
                  isOpen={isOpen}
                  onClose={() => extendClose()}
                  key={detail.index}
                >
                  <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                      <div className="extend-sheet-container">
                        <Extend
                          orderData={orderData}
                          extendClose={extendClose}
                          handlePendingOrder={ props.handlePendingOrder}
                        />
                      </div>
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              ) : (
                <></>
              )}
            </div>
            <div className="terminate-btn-container">
              <Button
                style={{ paddingTop: "1.1vh" }}
                onClick={() => OnclickTerminate(props?.items)}
              >
                <ShieldX size={18} style={{ marginRight: "1px" }} />
                Terminate
              </Button>
              <Button
                style={{ paddingTop: "0.9vh" }}
                onClick={() => HandelDelect(props?.items?._id)}
              >
                {" "}
                <Download size={19} style={{ marginRight: "1px" }} />
                Invoice
              </Button>
              {selectTerminateItem === "Terminate" ? (
                <Sheet
                  isOpen={isOpenterminate}
                  onClose={() => terminateClose()}
                >
                  <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                      {
                        <Terminate
                          orderData={orderData}
                          terminateClose={terminateClose}
                          handleOngoingOrder= { props.handleOngoingOrder}
                          handelCompletedOrder= { props.handelCompletedOrder}
                        />
                      }
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : null}
        {props?.items?.is_completed === true ? (
          <div className="card-other-item-container">
            <div className="feeback-conatiner-sheet">
              <Button
                style={{ paddingTop: "0.9vh" }}
                onClick={() => openFeedback(props?.items)}
              >
                {" "}
                <MessageSquareShare
                  size={19}
                  style={{ marginRight: "1px" }}
                />{" "}
                Feedback
              </Button>
              <Button
                style={{ paddingTop: "0.9vh" }}
                onClick={() => HandelDelect(props?.items?._id)}
              >
                {" "}
                <Download size={19} style={{ marginRight: "1px" }} /> Invoice
              </Button>
              {selectFeedbackItem === "Feedback" ? (
                <Sheet isOpen={isOpenfeedback} onClose={() => feedbackClose()}>
                  <Sheet.Container className="form-sheet">
                    <Sheet.Header />
                    <Sheet.Content>
                      {
                        <Feedback
                          orderData={orderData}
                          closeFeedback={feedbackClose}
                        />
                      }
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DasboardCard;
