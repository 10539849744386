import React from "react";
import MiniDrawer from "../desktopnavigationsidebar/DesktopNavigation";
import Navigation from "../navigationbar/Navigation";

function isPhone() {
  const userAgent = navigator.userAgent.toLowerCase();
  return /mobile|tablet|ipad|ipod|iphone|kindle|android|blackberry|playbook|silk/.test(
    userAgent
  );
}

function Iden({HandelLogoutTrigger}) {
  if (isPhone()) {
    return (
      <div>
        <Navigation HandelLogoutTrigger={HandelLogoutTrigger}></Navigation>
      </div>
    );
  } else {
    return (
      <div>
        <MiniDrawer></MiniDrawer>
      </div>
    );
  }
}

export default Iden;
