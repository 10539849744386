import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  TextField,
  Slider,
  Button,
  Autocomplete,
  Modal,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers";
import "./ServicesForm.css";
import dayjs from "dayjs";
import AddressForm from "../Address/AddressForm";
import { ArrowLeft, ArrowRight, X } from "lucide-react";
import { servicesapi, userOrderapi } from "../../Maid-api";
import { toast } from "react-toastify";
import Success from "../success-page/Success";
import { useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../redux-core/authentication/selectors";
import { notificationApi } from "../../Maid-api";

const {smsForOrderPlaced}=notificationApi;

const { newUserOrder } = userOrderapi;

const { getAllServices, getAllAddress } = servicesapi;

function ServiceForm(props) {
  const { register, handleSubmit, errors } = useForm();
  const [updatepagedecision, setUpdatepagedecision] = useState(4);
  const [services, setServices] = useState([]);
  const [issuccess, setSuccess] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  // console.log('selectedServices',selectedServices);
  // console.log('services',services);
  const members = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];
  const [numberOfMembers, setNumberOfMembers] = useState("");
  const TypeOfHouse = ["1BHK", "2BHK", "3BHK", "4BHK", "Multistorey", "Banglo"];
  const [housetype, setHouseType] = useState("");

  const [selectedType, setSelectedType] = useState("apartment");
  const [time, setTime] = useState();
  const [endTime, setEndTime] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [open, setOpen] = useState(false);
  const [nextPage, setNextPage] = useState(true);
  const [address, setAddress] = useState();

  const [housetypeError, setHousetypeError] = useState(false);
  const [houseTypeErrorMessage, setHouseTypeErrorMessage] = useState("");
  const [startTimeError, setStartTimeError] = useState(false);
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState();
  const [endTimeError, setEndTimeError] = useState(false);
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState();
  const [startDateError, setStartDateError] = useState(false);
  const [startDateErrorMessage, setStartDateErrorMessage] = useState();
  const [endDateError, setEndDateError] = useState(false);
  const [endDateErrorMessage, setEndDateErrorMessage] = useState();
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [loaclData, setLocalData] = useState();
  const [servicePrice, setServicePrice] = useState();
  const [sliderValue, setSliderValue] = useState([
    servicePrice?.min_price,
    servicePrice?.max_price,
  ]);
  const [minValue, setMinValue] = useState(servicePrice?.min_price);
  const [maxValue, setMaxValue] = useState(servicePrice?.max_price);
  // address radio button usestate
  const [selectedValue, setSelectedValue] = useState();
  const loginDetails=useSelector(makeSelectLoginDetail)
  console.log("maxValue", maxValue);
  console.log("minValue", minValue);
  console.log("sliderValue", sliderValue);
  console.log("servicePrice", servicePrice);
  console.log("loginDetails",loginDetails?.mobile_number);
  useEffect(() => {
    setMinValue(servicePrice?.min_price);
    setMaxValue(servicePrice?.max_price);
    setSliderValue([servicePrice?.min_price, servicePrice?.max_price]);
  }, [servicePrice]);

  const MAX = servicePrice?.max_price;
  const MIN = servicePrice?.min_price;
  const marks = [
    {
      value: MIN,
      label: "Min",
    },
    {
      value: MAX,
      label: "Max",
    },
  ];

  const HandelSliderChange = (event, newValue) => {
    console.log("event", event);
    console.log("newValue", newValue);
    setSliderValue(newValue);
    setMinValue(event.target.value[0]);
    setMaxValue(event.target.value[1]);
  };
  // console.log("time", dayjs(time).format("HH:mm"));

  // console.log('props.serviceId',props.serviceId);

  //console.log("time", dayjs(time).format("HH:mm"));
  // address radio button function
  const handleChangee = (value) => {
    setSelectedValue(value);
    console.log(value);
  };

  //get all services
  useEffect(() => {
    getAllServices()
      .then((res) => {
        // console.log("res", res.data);
        const serviceNames = res.data.map((item) => item.service_name);
        setServices(serviceNames);
        // Assuming props.serviceId is the ID of the selected service
        const selectedService = res.data.find(
          (item) => item._id === props.serviceId
        );
        console.log("selectedService:::", selectedService);
        setServicePrice(selectedService);
        setSelectedServices([selectedService.service_name]); // Make sure to wrap the service name in an array
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  //get all user address
  useEffect(() => {
    getAllAddress()
      .then((res) => {
        console.log("all-Address", res?.addresses);
        setAddress(res?.addresses);
      })
      .then((err) => {
        console.log("error", err);
      });
  }, []);

  //submit data in local storage
  const HandelSubmitinLocalStorage = () => {
    const localGetData = localStorage.getItem("data");
    if (!localGetData) {
      const Data = {
        num_of_member: numberOfMembers,
        house_type: housetype,
      };
      // Convert the data object to a JSON string
      const dataString = JSON.stringify(Data);
      // Store the JSON string in local storage
      localStorage.setItem("data", dataString);
    } else {
      const data = JSON.parse(localGetData);

      // Update the data object with new values
      data.num_of_member = numberOfMembers;
      data.house_type = housetype;

      // Convert the updated data object to a JSON string
      const updatedDataString = JSON.stringify(data);

      // Store the updated JSON string in local storage
      localStorage.setItem("data", updatedDataString);
    }
  };

  // get local data
  useEffect(() => {
    const localGetData = localStorage.getItem("data");
    const data = JSON.parse(localGetData);
    console.log("data::>>>>", data);
    if (data) {
      setNumberOfMembers(data?.num_of_member);
      setHouseType(data?.house_type);
    }
    setLocalData(data);
  }, []);

  //submit all service form data
  const onSubmit = (data) => {
    // console.log(data);
    if (!housetype && !time && !endTime && !startDate && !endDate) {
      console.log("test");
      setHousetypeError(true);
      setHouseTypeErrorMessage("Please Select House Type");
      setStartTimeError(true);
      setStartTimeErrorMessage("Please Select Start Time");
      setEndTimeError(true);
      setEndTimeErrorMessage("Please Select End Time");
      setStartDateError(true);
      setStartDateErrorMessage("Please Select Start Date");
      setEndDateError(true);
      setEndDateErrorMessage("Please Select End Date");
    } else if (!housetype) {
      console.log("test");
      setHousetypeError(true);
      setHouseTypeErrorMessage("Please Select House Type");
    } else if (!time) {
      setStartTimeError(true);
      setStartTimeErrorMessage("Please Select Start Time");
    } else if (!endTime) {
      setEndTimeError(true);
      setEndTimeErrorMessage("Please Select End Time");
    } else if (!startDate) {
      setStartDateError(true);
      setStartDateErrorMessage("Please Select Start Date");
    } else if (!endDate) {
      setEndDateError(true);
      setEndDateErrorMessage("Please Select End Date");
    } else {
      const Data = {
        service_id: props.serviceId,
        address_id: selectedValue,
        requirement_type: props.dicisionsend,
        num_of_member: numberOfMembers,
        house_type: housetype,
        start_time: time ? dayjs(time).format("HH:mm") : "",
        end_time: endTime ? dayjs(endTime).format("HH:mm") : "",
        from_date: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
        to_date: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
        min_range: minValue,
        max_range: maxValue,
      };
      console.log("Data", Data);
      newUserOrder(Data)
        .then((res) => {
          console.log("res", res);
          // toast.success(res?.message);
          setSuccess(true);
          smsForOrderPlaced({mobile_number:loginDetails?.mobile_number}).then((res)=>{
console.log("dataString",res);
          }).catch((err)=>{
            console.log(err);
          })
        })
        .catch((err) => {
          console.log("err", err);
        });
      HandelSubmitinLocalStorage();
    }
  };

  // handel back to dashboard
  const HandelBackToDashboard = () => {
    props.BackToDashboard(0);
    setSuccess(false);
  };

  const handleChange = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedServices(value);
  };

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
    // console.log(event.target.value);
  };

  const HadelNewAddressModalOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const HandelNextPage = () => {
    if (selectedValue) {
      setNextPage(false);
    } else {
      toast.warn("Please select the address !!");
    }
  };
  const HandelBackServicePage = () => {
    props.decision(updatepagedecision);
  };
  console.log(issuccess);
  // modal close call back function
  const ModalCallBackFunction = () => {
    setOpen(false);
    getAllAddress()
      .then((res) => {
        // console.log("all-Address", res?.addresses);
        setAddress(res?.addresses);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // Handel select House type
  const HandelHouseType = (value) => {
    setHouseType(value);
    setHousetypeError(false);
  };
  // Handel Select Start time
  const HandelStartTime = (value) => {
    setTime(value);
    setStartTimeError(false);
  };

  // Handel select End time
  const HandelEndTime = (value) => {
    if (value.isBefore(time)) {
      // Check if the selected end time is before the start time
      setEndTimeError(true); // Set the end time error to true
      setEndTimeErrorMessage("End time cannot be before start time."); // Set an error message
    } else {
      setEndTime(value); // Set the end time
      setEndTimeError(false); // Reset the end time error
    }
  };

  const currentDate = dayjs();
  // Handel select start date
  const handleDateChange = (date) => {
    setStartDate(date);
    setStartDateError(false);
  };

  // Handel select end Date
  const HandelEndDate = (value) => {
    setEndDate(value);
    setEndDateError(false);
  };
  console.log("address", address);
  return (
    <>
      {issuccess === false ? (
        <div className="body-container">
          <div className="form-card">
            <form onSubmit={handleSubmit(onSubmit)}>
              {nextPage === true ? (
                <div>
                  <button
                    className="serviceform-leftarrow-btn"
                    onClick={() => HandelBackServicePage()}
                  >
                    <ArrowLeft
                      color="white"
                      size={21}
                      style={{
                        marginTop: "0vh",
                        marginLeft: "0vw",
                        paddingTop: "0.7vh",
                      }}
                    />
                  </button>
                  <FormControl fullWidth className="from-control">
                    {/* <InputLabel id="service-label">Services</InputLabel> */}
                    <Autocomplete
                      sx={{
                        height: "7vh",
                      }}
                      style={{ height: "7vh" }}
                      multiple
                      options={services}
                      //  getOptionLabel={(option) => option.service_name}
                      disablePortal
                      disabled
                      value={selectedServices}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Services"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: null, // This line removes the dropdown icon
                          }}
                        />
                      )}
                    />
                  </FormControl>
                  <div
                    className="new-address-card"
                    onClick={HadelNewAddressModalOpen}
                  >
                    <Button style={{ color: "#385a64" }}>Add Address</Button>
                  </div>
                  <div
                    style={{
                      height: "52vh",
                      overflow: "scroll",
                      marginTop: "1vh",
                      paddingBottom: "1vh",
                    }}
                  >
                    {address?.map((item) => (
                      <div className="new-address-cards">
                        <div className="address-radio-btn-container">
                          <Radio
                            checked={selectedValue === item?._id}
                            onChange={() => handleChangee(item?._id)}
                            value="a"
                            name="radio-buttons"
                            //  inputProps={{ "aria-label": "A" }}
                            sx={{ padding: "0" }}
                          />
                        </div>
                        <div className="address-text-item">
                          {item?.floor_tower_num} <br />
                          {item?.flat_house_num} <br />
                          {item?.apartment_name}
                          {item?.apartment && (
                            <>
                              {item?.apartment?.apartment_name}
                              <br />
                              {item?.apartment?.location}
                              <br />
                              {item?.apartment?.city}
                              <br />
                              {item?.apartment?.state}&nbsp;
                              {item?.apartment?.pincode}
                              <br />
                            </>
                          )}{" "}
                          {item?.pincode && (
                            <>
                              <br /> {item?.landmark} <br /> {item?.address}{" "}
                              <br />
                              {item?.city} {item?.pincode} {item?.state}{" "}
                              {item?.country}
                              <br />
                            </>
                          )}
                        </div>
                                  
                      </div>
                    ))}
                  </div>

                  {/* <Button style={{ color: "#385a64" }} onClick={HandelNextPage}>
            Next
          </Button> */}
                  <button
                    className="serviceform-rightarrow-btn"
                    onClick={HandelNextPage}
                  >
                    <ArrowRight
                      color="white"
                      size={21}
                      style={{
                        marginTop: "0vh",
                        marginLeft: "0vw",
                        paddingTop: "0.7vh",
                      }}
                    />
                  </button>
                </div>
              ) : (
                <div style={{ height: "78vh" }}>
                  <div className="user-family-details-form-container">
                    {/* <Button
            style={{ color: "#385a64" }}
            onClick={() => setNextPage(true)}
          >
            back
          </Button> */}
                    <button
                      className="serviceform-leftarrow-btn"
                      onClick={() => setNextPage(true)}
                    >
                      <ArrowLeft
                        color="white"
                        size={21}
                        style={{
                          marginTop: "0vh",
                          marginLeft: "0vw",
                          paddingTop: "0.7vh",
                        }}
                      />
                    </button>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "2vh", height: "7vh !important" }}
                    >
                      <InputLabel id="service-label">
                        Number of Family Members
                      </InputLabel>
                      <Select
                        value={
                          numberOfMembers
                            ? numberOfMembers
                            : loaclData?.num_of_member
                        }
                        onChange={(e) => setNumberOfMembers(e.target.value)}
                        label="Number of Family Members"
                      >
                        {members.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div>
                      <FormControl
                        fullWidth
                        className="from-control"
                        style={{ marginTop: "3vh" }}
                      >
                        <InputLabel id="service-label">House Type</InputLabel>
                        <Select
                          value={housetype ? housetype : loaclData?.house_type}
                          onChange={(e) => HandelHouseType(e.target.value)}
                          label="House Type"
                        >
                          {TypeOfHouse.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {housetypeError && (
                        <p style={{ color: "red" }}>{houseTypeErrorMessage}</p>
                      )}
                    </div>

                    <div className="from-control" style={{ marginTop: "2vh" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="Start Time"
                            value={time}
                            onChange={HandelStartTime}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {startTimeError && (
                        <p style={{ color: "red" }}>{startTimeErrorMessage}</p>
                      )}
                    </div>
                    <div className="from-control" style={{ marginTop: "2vh" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="End Time"
                            value={endTime}
                            onChange={HandelEndTime}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {endTimeError && (
                        <p style={{ color: "red" }}>{endTimeErrorMessage}</p>
                      )}
                    </div>

                    <div style={{ marginTop: "2vh" }}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="Start Date"
                              // onChange={setStartDate}
                              onChange={handleDateChange}
                              value={startDate}
                              format="DD/MM/YYYY"
                              minDate={currentDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {startDateError && (
                          <p style={{ color: "red" }}>
                            {startDateErrorMessage}{" "}
                          </p>
                        )}
                      </div>
                      <div style={{ marginTop: "2vh" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              label="End Date"
                              onChange={HandelEndDate}
                              value={endDate}
                              format="DD/MM/YYYY"
                              minDate={startDate || currentDate}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {endDateError && (
                          <p style={{ color: "red" }}>{endDateErrorMessage} </p>
                        )}
                      </div>
                    </div>

                    <div className="from-control " style={{ marginTop: "2vh" }}>
                      <InputLabel id="service-label">
                        Select Min & Max Price Per Day
                      </InputLabel>
                      <Slider
                        marks={marks}
                        value={sliderValue}
                        onChange={HandelSliderChange}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        min={MIN}
                        max={MAX}
                        sx={{
                          width: "79vw",
                          marginLeft: "5vw",
                          marginRight: "4vw",
                        }}
                      />

                      <div className="range-value-container-min">
                        &#8377; {minValue}
                      </div>

                      <div className="range-value-container-max">
                        &#8377; {maxValue}
                      </div>
                    </div>
                  </div>
                  {/* <div className="right-arrow-btn-conatiner">
                    <Button variant="contained" type="submit">
                      {" "}
                      Submit
                    </Button>
                  </div> */}
                  <button className="serviceform-rightarrow-btn" type="submit">
                    <ArrowRight
                      color="white"
                      size={21}
                      style={{
                        marginTop: "0vh",
                        marginLeft: "0vw",
                        paddingTop: "0.7vh",
                      }}
                    />
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : (
        <Success back={HandelBackToDashboard} />
      )}

      {/* {issuccess ?  : ""} */}

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          width: "100vw",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          marginTop={"5vh"}
        >
          <Grid item xs={3}>
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                width: "100%",
                maxWidth: "400px",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{
                  left: "76%",
                }}
              >
                <X />
              </IconButton>

              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ textAlign: "center", marginLeft: "-12vw" }}
              >
                Add new address
              </Typography>
              <AddressForm modalClose={ModalCallBackFunction} />
            </div>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
export default ServiceForm;
