import axios from "axios";
import constants from "./constants";
import store from "../store";
var fileDownload = require('js-file-download');
axios.defaults.baseURL = constants.API_URL;

const instance = axios.create({});
instance.interceptors.request.use(async (config) => {
  const value = localStorage.getItem("userToken");
  config.headers = {
    Authorization: `Bearer ${value}`,
    Accept: "application/json",
  };
  store.dispatch({ type: "spin", payload: true });
  return config;
});

instance.interceptors.response.use(
    function (response) {
      store.dispatch({ type: "spin", payload: false });
      return response;
    },
    function (error) {
      const res = error.response;
      store.dispatch({ type: "spin", payload: false });
  
      if (res.status === 401) {
        window.localStorage.removeItem("persist:root");
        window.localStorage.removeItem("userToken");
        window.localStorage.removeItem("refreshToken");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );

  const setAuthHeader = (token) => {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axios.defaults.withCredentials = true;
  };
   /**
   * create new user order
   * @param {*} order object
   * @returns
   */

   const newUserOrder =async(order) => {
    try{
        const response= await instance.post("api/createsUserOrder",order,
        {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods": "POST",
            },
 
        }
      );
      return response.data;
      
    }catch(error){
        throw error;
    }
  };
  

  //  get all pendingorder
  const getAllPendingOrder =async(service_name) =>{
    console.log("service_name}}",service_name);
    try{
        const response = await instance.get("/api/getPendingUserOrder",
        {
          params: {serviceNameText:service_name}
        },
      
      
        {
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":"GET"
            },
        }
      );
      return response.data;
    }catch(error){
        throw error;
    }
  };

  //get all ongoing order
  const getAllOngingOrder =async(service_name) =>{
    try{
        const response = await instance.get("/api/getOngingUserOrder",
        {
          params: {serviceNameText:service_name}
        },
      
      
        {
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":"GET"
            },
        }
      );
      return response.data;
    }catch(error){
        throw error;
    }
  };

  // get all completed order

  const getAllComplectedOrder =async(service_name) =>{
    try{
        const response = await instance.get("/api/getCompleteUserOrder",
        {
          params: {serviceNameText:service_name}
        },
      
      
        {
            headers:{
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":"GET"
            },
        }
      );
      return response.data;
    }catch(error){
        throw error;
    }
  };

  // order id wise user order terminate
  const terminateuserOrder = async (orderId, data) => {
    try {
      const response = await instance.put(
        "/api/terminateUserOrder",
        data, // Pass data directly as the second argument
        {
          params: { userOrderId: orderId }, // Move params to the third argument
          headers: {
            "Access-Control-Allow-Origin": "*", // Move headers to the fourth argument
            "Access-Control-Allow-Methods": "PUT"
          }
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  // give maid feedback in user order
  const staffFeedback =async(feedback) => {
    try{
        const response= await instance.post("api/sendMaidFeedback",feedback,
        {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods": "POST",
            },
 
        }
      );
      return response.data;
      
    }catch(error){
        throw error;
    }
  };
  
  const createOrder =async(data) => {
    try{
        const response= await instance.post("api/createOrder",data,
        {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods": "POST",
            },

        }
      );
      return response.data;

    }catch(error){
        throw error;
    }
  }
  const verifyPayment =async(data) => {
    try{
        const response= await instance.put("api/verifyPayment",data,
        {
            headers:{
                "Access-Control-Allow-Origin":"*",
                "Access-Control-Allow-Methods": "PUT",
            },

        }
      );
      return response.data;

    }catch(error){
        throw error;
    }
  };

// get costmer feedback
  const getCostmerFeedback =async(orderId) =>{

    try{
        const response = await instance.get("/api/getFeedbackByOrderId",
         {
          params: { 
            orderId:orderId
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET"
          },
        });
      
        return response.data;
      } catch (error) {
       
        throw error;
      }
    };
// complete order in ongoing order
    const completeUserOrder = async (orderId) => {
      try {
        const response = await instance.patch(
          "/api/complete-user-order",
          { orderId: orderId },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "PATCH"
            }
          }
        );
    
        return response.data;
      } catch (error) {
        throw error;
      }
    };
    

    const getInvoiceDownload = async (orderId) => {
      console.log("orderId",orderId);
      try {
         await axios.get('/api/download-invoice', {
          params: { orderId: orderId },
          responseType: "blob"
        }).then((res)=>{
        console.log("res.data",res.data);
        fileDownload(res.data,"invoice.pdf")
      })     
      } catch (error) {
        throw error;
      }
    };
    
  export default {
    newUserOrder,
    getAllPendingOrder,
    getAllOngingOrder,
    getAllComplectedOrder,
    terminateuserOrder,
    staffFeedback,
    verifyPayment,
    createOrder,
    getCostmerFeedback,
    completeUserOrder,
    getInvoiceDownload
}