import { toast } from "react-toastify";
import { authService } from "../../Maid-api";
import { ActionTypes } from "./constants";

const { signin } = authService;

/**
 * Action for  the login details
 * @param {*} loginDetails
 * @returns
 */
export const setLoginDetails = (loginDetails) => ({
  type: ActionTypes.SET_LOGINDETAILS,
  payload: loginDetails,
});

/**
 * Action for login
 * @param {*} pho
 * @param {*} mpin
 * @returns
 */
export const login = (pno, mpin) => {
  return async (dispatch) => {
    try {
      const response = await signin(pno, mpin);
      //console.log('response',response);
      dispatch(setLoginDetails(response));
      toast.success(response.message)
      return response;
    } catch (err) {
        console.log("err:-",err);
        toast.error(err.response.data.message)
    }
  };
};
