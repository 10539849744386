import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  TextField,
  Button,
  unstable_ClassNameGenerator,
} from "@mui/material";
import "./Ragistration.css";
import OtpInput from "react-otp-input";
import { authService } from "../../Maid-api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import packageJson from '../../../package.json';

const { register, VerifyOtp, CreateMpin } = authService;

const Registration = () => {
  // State to store form values
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    // email: ""
  });
  const [otp, setOtp] = useState("");
  const [visibleInputField, setVisibleInputField] = useState(false); // State to track OTP verification
  const [otpVerified, setOtpVerified] = useState(false); // State to track OTP verification
  const [mpinVisible, setMpinVisible] = useState(false); // State to track MPIN field visibility
  const [mpin, setMpin] = useState("");
  const [nameError, setNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [numbererrorMsg, setNumbererrorMsg] = useState(null);
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState();
  const [pinError, setPinError] = useState(false);
  const [pinErrorMessage, setPinErrorMessage] = useState();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [verifyId, setVerifyId] = useState();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(null); // Initial state set to null
  const [sendOtp, setSendOtp] = useState(true);  // Initially, OTP can be sent
  // Handle form field changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
// 1min otp again send time out
const HandelAgainSendOtpTime = () => {
  setSendOtp(false); // Disable sending OTP
  setTimeLeft(60);   // Start countdown from 60 seconds

  const countdown = setInterval(() => {
    setTimeLeft(prevTime => {
      if (prevTime <= 1) {
        clearInterval(countdown);
        setSendOtp(true); // Enable sending OTP again
        console.log("One minute has passed. You can send the OTP again.");
        return null; // Clear the countdown
      }
      return prevTime - 1;
    });
  }, 1000);
};


  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("event", event);
    const { name, mobile } = formData;
    console.log(formData);
    if (!name) {
      setNameError(true);
    }
    if (!mobile) {
      setMobileError(true);
      setNumbererrorMsg("Please enter your mobile number");
    }
    if (name && mobile) {
      // Form is valid, proceed with submission
      console.log(formData);

      register(formData.name, formData.mobile)
        .then((response) => {
          console.log("response", response); // Log the response data to the console
          if (response?.code == 200) {
            setOtpVerified(true);
            setVisibleInputField(true);
            toast.success(response.message);
            setMobileNumber(formData.mobile);
            setVerifyId(response?.data?.verify_id)
            HandelAgainSendOtpTime()
          }
        })
        .catch((error) => {
          // console.log("Error:", error); // Log any errors to the console
          toast.error(error.response.data.message);
        });
    }
  };

  // Handle OTP verification
  const handleVerify = () => {
    console.log("OTP:", otp); // Log OTP value to console
    // You can perform further actions here, such as sending OTP to a server for verification
    if (otp.length !== 4) {
      setOtpError(true);
      setOtpErrorMessage("Please enter a valid OTP");
    } else {
      VerifyOtp(mobileNumber,verifyId,otp)
        .then((res) => {
          console.log("Otp Verify Response", res);
          toast.success("Otp Verifyed !!");
          setOtpError(false);
          setOtpVerified(false); // Mark OTP as verified
          setMpinVisible(true); // Show MPIN field
        })
        .catch((err) => {
          console.log("Error", err);
         toast.error(err?.response?.data?.message)
        });
    }
  };

  // Handel Create Mpin
  const handleCreateMpin = () => {
    if (mpin.length !== 4) {
      setPinError(true);
      setPinErrorMessage("Please enter a pin");
    } else {
      setPinError(false);
      setPinErrorMessage(" ");
      console.log("mpin", mpin);
      CreateMpin(mpin)
        .then((res) => {
          console.log("MPIN created successfully ", res);
          toast.success(res.message);
          navigate("/login");
        })
        .catch((err) => {
          console.log("Error in creating mpin ", err);
          toast.error("Something went wrong! Please try again.");
        });
    }
  };
  useEffect(() => {
    if (formData.mobile && !/^[6-9]\d{9}$/.test(formData.mobile)) {
      setMobileError(true);
      setNumbererrorMsg("Please enter valid number");
    } else {
      setMobileError(false);
    }
  }, [formData.mobile]);

  // handel back to login page
  const HandelBackLogin = () => {
    navigate("/login");
  };
  return (
    <div className="registration-container">
      <div className="register-card">
        <div className="card-containers">
          <div className="background-image-container">
            <img
              src="./images/splash.jpg"
              alt="background image"
              className="backgroud-image1"
            />
          </div>
        </div>
        <div className="card-container">
          <div className="container">
            {!visibleInputField && (
              <form onSubmit={handleSubmit}>
                <div>
                  <FormControl>
                    <TextField
                      name="name"
                      label="Name"
                      placeholder="Enter your name"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formData.name}
                      onChange={handleChange}
                      //  required
                      error={nameError}
                      helperText={nameError ? "Please enter your name" : ""}
                      InputProps={{
                        style: {
                          borderRadius: "5px", // Adjust the radius as needed
                          backgroundColor: "white",
                          height: "7vh",
                        },
                      }}
                    />
                    <FormHelperText />
                  </FormControl>
                </div>
                <div>
                  <FormControl>
                    <TextField
                      name="mobile"
                      label="Mobile Number"
                      placeholder="Enter mobile number"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={formData.mobile}
                      onChange={handleChange}
                      // required
                      error={mobileError}
                      helperText={mobileError ? numbererrorMsg : ""}
                      type="tel"
                      inputProps={{
                        maxLength: 10,
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "5px", // Adjust the radius as needed
                          backgroundColor: "white",
                          height: "7vh",
                        },
                      }}
                    />
                    <FormHelperText />
                  </FormControl>
                </div>

                <div className="button-container">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="custom-button"
                  >
                    Submit
                  </Button>
                </div>
                <div className="already-member">
                  {" "}
                  <label className="text-style"> Already a member !</label>{" "}
                  <label
                    style={{ color: "blue", marginTop: "1vh" }}
                    onClick={HandelBackLogin}
                  >
                    SignIn
                  </label>
                </div>
              </form>
            )}
            {otpVerified && (
              <div>
                <div className="otp_input">
                  <div
                    style={{
                      marginBottom: "5vw",
                      fontSize: "1.3rem",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Verification code
                  </div>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => (
                      <input
                        {...props}
                        type="tel"
                        style={{
                          width: "40px",
                          height: "40px",
                          fontSize: "16px",
                          textAlign: "center",
                          margin: "0 5px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                        }}
                      />
                    )}
                  />
                  <FormHelperText error={otpError}>
                    {otpErrorMessage}
                  </FormHelperText>
                </div>
                
                <div>
                  <div className="button-container">
                    <Button
                      onClick={handleVerify}
                      variant="contained"
                      color="primary"
                      className="custom-button"
                    >
                      Verify
                    </Button>
                  </div>
                </div>
                <div style={{textAlign:'center', marginTop:'1vh'}}>
                  <p>Resend Otp in <span style={{color:'green'}}>{timeLeft}</span> </p>
                  {sendOtp && (
 <span style={{color:'blue'}} onClick={handleSubmit}><u>Resend OTP</u> </span>
                  )}
                 
                </div>
              </div>
            )}
            {mpinVisible && (
              <>
                <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                  <div className="input-pin">
                    <label
                      htmlFor="mpin"
                      style={{
                        marginBottom: "5vw",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Set Your Login PIN
                    </label>
                    <OtpInput
                      value={mpin}
                      onChange={setMpin}
                      numInputs={4}
                      renderSeparator={<span> </span>}
                      renderInput={(props) => (
                        <input
                          {...props}
                          type="tel"
                          style={{
                            width: "40px",
                            height: "40px",
                            fontSize: "16px",
                            textAlign: "center",
                            margin: "0 5px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                            // borderTop: "none",
                            // borderRight: "none",
                            // borderLeft: "none",
                            margin: "10px",
                          }}
                        />
                      )}
                    />
                    <FormHelperText error={pinError}>
                      {pinErrorMessage}
                    </FormHelperText>
                  </div>
                </div>
                <div>
                  <div
                    className="button-container"
                    style={{ marginTop: "-10px" }}
                  >
                    <Button
                      onClick={handleCreateMpin}
                      variant="contained"
                      color="primary"
                      className="custom-button"
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="footer">
        <div style={{ fontSize: "0.7rem" }}>V {packageJson.version} ©2024</div>
        <div style={{ fontSize: "0.6rem" }}>Powered by: SamMegh Technology</div>
      </div>
    </div>
  );
};

export default Registration;
