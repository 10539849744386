import React, { useEffect } from "react";
import "./Profile.css";
import CardMedia from "@mui/material/CardMedia";
import Sheet from "react-modal-sheet";
import { RWebShare } from "react-web-share";
import CloseIcon from "@mui/icons-material/Close";
import OtpInput from "react-otp-input";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";
import {
  MapPin,
  Share2,
  Star,
  MessageSquareShare,
  LogOut,
  Mail,
  SquareUser,
  PhoneForwarded,
  Pencil,
  BookmarkX,
} from "lucide-react";
import { authService, servicesapi } from "../../Maid-api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { makeSelectLoginDetail } from "../../redux-core/authentication/selectors";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { useState } from "react";

const stateSelector = createSelector(makeSelectLoginDetail, (loginDetails) => ({
  loginDetails,
}));

const { signout, getUserDetail } = authService;
const { updateUserProfile, giveAppFeedback } = servicesapi;
const Profile = ({ HandelLogoutTrigger }) => {
  const { loginDetails } = useSelector(stateSelector);
  const [useDetail, setUserDetail] = useState();
  const [profileedit, setProfileedit] = useState(true);
  const [isOpenRateus, setOpenRateus] = useState(false);
  const [shareopen, setShareopen] = useState(false);
  const [islogout, setIslogout] = useState(false);
  const [name, setName] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [email, setEmail] = useState();
  const [otp, setOtp] = useState("");
  const [updateProfilePhoneNumber, setUpdateProfilePhoneNumber] =
    useState(false);

  console.log("useDetail", useDetail);
  console.log("name", name);
  console.log("mobileNumber", mobileNumber);
  console.log("email", email);
  console.log("isOpenRateus", isOpenRateus);

  useEffect(() => {
    getUserDetail()
      .then((res) => {
        console.log("response", res);
        setUserDetail(res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);
  useEffect(() => {
    if (useDetail) {
      setName(useDetail.full_name || "");
      setMobileNumber(useDetail.mobile_number || "");
      setEmail(useDetail.email || "");
    }
  }, [useDetail]);
  //console.log("loginDetails",loginDetails);

  // handel name change
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  // handel mobile number change
  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };
  // handel email change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // handel edit profile
  const HandelEditProfile = () => {
    if (mobileNumber !== useDetail?.mobile_number) {
      console.log("test edit");
      setUpdateProfilePhoneNumber(true);
    } else {
      const data = {
        full_name: name,
        mobile_number: mobileNumber,
        email: email,
      };
      updateUserProfile(data)
        .then((res) => {
          console.log("res", res);
          getUserDetail()
            .then((res) => {
              console.log("response", res);
              setUserDetail(res);
            })
            .catch((err) => {
              console.log("err", err);
            });
          setProfileedit(true);
          toast.success(res?.message);
        })
        .catch((err) => {
          console.log("err", err);
        });
      console.log("data", data);
    }
  };

  const UdatePhoneNumberClose = () => {
    setUpdateProfilePhoneNumber(false);
  };

  const UpdatePhoneNumberOtp = () => {
    console.log("otp", otp);
    if (otp.length === 4) {
      const data = {
        full_name: name,
        mobile_number: mobileNumber,
        email: email,
      };
      updateUserProfile(data)
        .then((res) => {
          console.log("res", res);
          getUserDetail()
            .then((res) => {
              console.log("response", res);
              setUserDetail(res);
            })
            .catch((err) => {
              console.log("err", err);
            });
          setProfileedit(true);
          toast.success(res?.message);
          setUpdateProfilePhoneNumber(false);
        })
        .catch((err) => {
          console.log("err", err);
        });
      console.log("data", data);
    }
  };

  function RateUs() {
    const [selectedStars, setSelectedStars] = useState(0);
    const [textAreaData, setTextAreaData] = useState("");

    const handleStarClick = (e, index) => {
      setSelectedStars(index + 1);
    };

    const handleSubmit = () => {
      const data = {
        feedback: textAreaData,
        rating: selectedStars,
      };
      giveAppFeedback(data)
        .then((res) => {
          console.log("res", res);
          setOpenRateus(false);
          toast.success(res?.message);
        })
        .catch((err) => {
          console.log("err", err);
        });

      console.log("User rated us: ", selectedStars, textAreaData);
    };
    const handleTextAreaChange = (e) => {
      setTextAreaData(e.target.value);
    };

    return (
      <div className="rateus-form-container">
        <h2 style={{ color: "#1a434e" }}>Rate Us</h2>
        <FormGroup>
          <div
            style={{
              display: "flex",
              gap: "2vw",
              paddingLeft: "1vw",
              marginLeft: "13vw",
              marginTop: "3vh",
              fontSize: "1.2rem",
            }}
          >
            <span style={{ paddingRight: "2vw" }}>Rating : </span>
            {Array.from({ length: 5 }, (_, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Star
                    strokeWidth={4}
                    color={selectedStars > i ? "#1a434e " : "#E2DFD0"}
                    onClick={(e) => handleStarClick(e, i)}
                  />
                }
              />
            ))}
          </div>
          <TextField
            label="Feedback"
            multiline
            rows={3}
            value={textAreaData}
            onChange={handleTextAreaChange}
          />
        </FormGroup>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    );
  }

  const RateUsClose = () => {
    setOpenRateus(false);
  };

  const openRateUs = () => {
    setOpenRateus(true);
  };

  console.log("loginDetails", loginDetails);
  const navigate = useNavigate();
  // Handel logout app
  const HandelLogout = async () => {
    try {
      await signout();
      localStorage.removeItem("data");
      navigate("/login");
      await HandelLogoutTrigger();

      toast.success("Logout Succesfull");
    } catch (error) {
      console.error(error);
      toast.error("Error logging out");
    }
  };

  const Share = () => {
    const currenturl = "tutorend.com";
    return (
      <div style={{ height: "auto" }}>
        <div className="share-icon-container">
          <FacebookShareButton url={currenturl} quote="Share The Application">
            <FacebookIcon />
          </FacebookShareButton>
          <TwitterShareButton url={currenturl} quote="Share The Application">
            <TwitterIcon />
          </TwitterShareButton>
          <EmailShareButton url={currenturl} quote="Share The Application">
            <EmailIcon />
          </EmailShareButton>
          <WhatsappShareButton url={currenturl} quote="Share The Application">
            <WhatsappIcon />
          </WhatsappShareButton>
          <InstapaperShareButton url={currenturl} quote="Share The Application">
            <InstapaperIcon />
          </InstapaperShareButton>
        </div>
      </div>
    );
  };

  const HandleLogoutmodalopen = () => {
    setIslogout(true);
  };
  const HandleLogoutmodalclose = () => {
    setIslogout(false);
  };

  return (
    <div className="profile-page-parent-container">
      <div className="profile-page-second-parent">
        <div className="profile-page-other-detailes-container">
          <div className="profile-page-modal-components">
            <div className="profile-page-modal-component-item">
              <div className="setting-text">
                <span>MY Profile</span>{" "}
                {profileedit ? (
                  <span>
                    <Pencil
                      size={22}
                      style={{ marginRight: "5vw" }}
                      onClick={() => setProfileedit(false)}
                    />
                  </span>
                ) : (
                  <span>
                    <BookmarkX
                      size={22}
                      style={{ marginRight: "5vw" }}
                      onClick={() => setProfileedit(true)}
                    />
                  </span>
                )}
              </div>
              <div className=" modal-component">
                <span>
                  <SquareUser
                    size={22}
                    style={{
                      marginTop: "1vh",
                      marginLeft: "3vw",
                      marginRight: "0px",
                    }}
                  />
                </span>

                <span style={{ paddingTop: "1vh" }}> Name :</span>
                {profileedit ? (
                  <span style={{ paddingTop: "1vh" }}>
                    {" "}
                    {useDetail?.full_name}
                  </span>
                ) : (
                  <span style={{ height: "5vh" }}>
                    {" "}
                    <TextField
                      id="outlined-basic"
                      // label="Name"
                      variant="outlined"
                      size="small"
                      defaultValue={useDetail?.full_name}
                      onChange={handleNameChange}
                      sx={{
                        marginTop: "0.2vh",
                        marginLeft: "1.1vw",
                        width: "60vw",
                      }}
                    />
                  </span>
                )}
              </div>
              <div className=" modal-component">
                <span>
                  <PhoneForwarded
                    size={22}
                    style={{
                      marginTop: "1vh",
                      marginLeft: "3vw",
                      marginRight: "1px",
                    }}
                  />
                </span>
                <span style={{ paddingTop: "1vh" }}>Phone :</span>
                {profileedit ? (
                  <span style={{ paddingTop: "1.1vh" }}>
                    {" "}
                    {useDetail?.mobile_number}
                  </span>
                ) : (
                  <span style={{ height: "5vh" }}>
                    {" "}
                    <TextField
                      id="outlined-basic"
                      // label="Phone Number"
                      variant="outlined"
                      size="small"
                      type="tel"
                      defaultValue={useDetail?.mobile_number}
                      onChange={handleMobileNumberChange}
                      sx={{
                        marginTop: "0.2vh",
                        marginLeft: "0.6vw",
                        width: "60vw",
                      }}
                    />
                  </span>
                )}
              </div>
              <div className=" modal-component">
                <span>
                  <Mail
                    size={22}
                    style={{
                      marginTop: "1vh",
                      marginLeft: "3vw",
                      marginRight: "1px",
                    }}
                  />
                </span>
                <span style={{ paddingTop: "1vh" }}>E-mail :</span>
                {profileedit ? (
                  <span style={{ paddingTop: "1vh" }}> {useDetail?.email}</span>
                ) : (
                  <span style={{ height: "5vh" }}>
                    {" "}
                    <TextField
                      id="outlined-basic"
                      // label="Phone Number"
                      variant="outlined"
                      size="small"
                      type="email"
                      defaultValue={useDetail?.email}
                      onChange={handleEmailChange}
                      placeholder="abc@gmail.com"
                      sx={{ marginTop: "0.2vh", width: "60vw" }}
                    />
                  </span>
                )}
              </div>
              {/* <div className="modal-component"></div> */}

              {profileedit ? null : (
                <div className=" update-btn-of-modal-component">
                  <Button onClick={HandelEditProfile}>Update</Button>
                </div>
              )}
            </div>
          </div>
          {/* <hr></hr> */}
          <div className="profile-page-support-info">
            <div className="profile-page-support-info-items">
              <div className="support-us-text"> Support US</div>
              <div className="support-info-items">
                <span>
                  <Share2
                    size={22}
                    style={{
                      marginTop: "1vh",
                      marginLeft: "2.6vw",
                      marginRight: "1px",
                    }}
                  />
                </span>
                <span
                  onClick={() => setShareopen(true)}
                  style={{ paddingTop: "1vh" }}
                >
                  share
                </span>

                <Sheet isOpen={shareopen} onClose={() => setShareopen(false)}>
                  <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>{<Share />}</Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              </div>
              <div className="support-info-items">
                <span>
                  <Star
                    size={22}
                    style={{
                      marginTop: "1vh",
                      marginLeft: "2.6vw",
                      marginRight: "1px",
                    }}
                  />
                </span>

                <span onClick={openRateUs} style={{ paddingTop: "1vh" }}>
                  Rate Us
                </span>
                <Sheet isOpen={isOpenRateus} onClose={RateUsClose}>
                  <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                      {
                        <RateUs
                          // orderData={orderData}
                          closeFeedback={RateUsClose}
                        />
                      }
                    </Sheet.Content>
                  </Sheet.Container>
                  <Sheet.Backdrop />
                </Sheet>
              </div>
              {/* <div className="support-info-items">
                <span>
                  <MessageSquareShare
                    size={22}
                    style={{
                      marginTop: "1vh",
                      marginLeft: "2.6vw",
                      marginRight: "1px",
                    }}
                  />
                </span>
                <span style={{ paddingTop: "1vh" }}>Feedback</span>
              </div> */}
              <div
                onClick={() => HandleLogoutmodalopen()}
                className="support-info-items"
              >
                <span>
                  <LogOut
                    size={23}
                    style={{
                      marginTop: "1vh",
                      marginLeft: "2.6vw",

                      marginRight: "1px",
                    }}
                  />{" "}
                </span>
                <span style={{ paddingTop: "1vh" }}>Log Out</span>
              </div>

              <Modal
                open={islogout}
                onClose={HandleLogoutmodalclose}
                aria-labelledby="logout-modal-title"
                aria-describedby="logout-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    width: "80vw",
                    transform: "translate(-50%, -50%)",

                    bgcolor: "background.paper",

                    boxShadow: 24,
                    p: 4,
                    borderRadius: "4px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    id="logout-modal-title"
                    // variant="h6"
                    component="h2"
                    sx={{fontSize:'0.9em'}}
                  >
                    Are you sure you want to logout ?
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      onClick={HandelLogout}
                      sx={{ mr: 2, backgroundColor: " #1a434e" }}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={HandleLogoutmodalclose}
                      sx={{ mr: 2, backgroundColor: " red", color: "white" }}
                    >
                      No
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={updateProfilePhoneNumber}
        onClose={UdatePhoneNumberClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          marginTop: "12vh",
          width: "98vw",
          marginLeft: "1vw",
          marginRight: "1vw",
        }}
      >
        <div className="profilephonenumber-update-container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <h2 style={{ paddingTop: "0.8vh" }}>Update Phone Number</h2>
            <Button onClick={UdatePhoneNumberClose}>
              <CloseIcon style={{ fontSize: "4vh" }} />
            </Button>
          </div>
          <div
            style={{
              alignItems: "center",
              marginLeft: "24vw",
              marginTop: "2vh",
            }}
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              separator={<span>-</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  style={{
                    width: "40px",
                    height: "40px",
                    fontSize: "16px",
                    textAlign: "center",
                    margin: "0 5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                />
              )}
            />
          </div>
          <div style={{ marginTop: "3vh" }}>
            {" "}
            <Button onClick={UpdatePhoneNumberOtp}>Submit</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
