import { createSelector } from "reselect";

const loginDetailsState = (state) => state.loginDetails.loginDetails;

/**
 * Selector for Getting login details
 */
export const makeSelectLoginDetail = createSelector(
    loginDetailsState,
    (loginDetails) => loginDetails
  );
  