import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./AddressForm.css";
import { servicesapi } from '../../Maid-api'
import { useSelector } from "react-redux";
import { makeSelectLoginDetail } from "../../redux-core/authentication/selectors";

const {createUserAddress,getAllApartments}=servicesapi;
const AddressForm = (props) => {
  const { register, handleSubmit,  formState: { errors } } = useForm();
  const [selectedType, setSelectedType] = useState("apartment");
  const [selectOthers,setSelectOthers]=useState(false)
  const [selectedApartment, setSelectedApartment] = useState("");
  const  loginDetails  = useSelector(makeSelectLoginDetail);
  const [allApartment,setAllAparment]=useState()
  console.log('loginDetails',loginDetails);

   useEffect(()=>{
    getAllApartments().then((res)=>{
 console.log(res.data.map((item)=>item.apartment_name));
 setAllAparment(res.data)
    }).catch((err)=>{
      console.log('error in fetching apartments');
    })
   },[])

   


  //Hande submit form
  const onSubmit = (data,e) => {
    console.log("Data:", data);
    const insertData={
      user_id: loginDetails?.user_id,
      apartment_id: selectedApartment,
      apartment_name:data?.apartment_name,
      address_type:selectedType,
      address:data?.addressLine1,
      city:data?.city,
      pincode:data?.zippin,
      state:data?.state,
      country:data?.country,
      landmark:data?.landmark,
      floor_tower_num:data?.tower_floor,
      flat_house_num:data?.flat_house_number
    }
    console.log('insertData',insertData);
    createUserAddress(insertData).then((res)=>{
      console.log('response', res)
      e.target.reset(); // Reset the form fields
      setSelectedType("apartment"); // Reset selectedType to its default value
      setSelectedApartment(""); // Reset selectedApartment to its default value
      setSelectOthers(false); // Reset selectOthers to its default value
      props.modalClose()
    }).catch((err)=>{
      console.log('ERROR',err)
    })
  };
  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
    console.log(event.target.value);
  };

  const handleChangeApartment = (value) => {
    setSelectedApartment(value);
    console.log("value",value);
   
if(value==='Other'){
  setSelectOthers(true)
}
  };

  

  return (
    <div className="address_body">
      <div className="from-container1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="radio-btn-items-container">
            <FormControl
              fullWidth
              className="from-control"
              variant="outlined"
              style={{ marginTop: "3vh" }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="typeoflocation"
                value={selectedType}
                onChange={handleChangeType}
              >
                <FormControlLabel
                  value="apartment"
                  control={<Radio />}
                  label="Apartment"
                />
                <FormControlLabel
                  value="independent home"
                  control={<Radio />}
                  label="Independent home"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="add-new-address-modal-container">
            {selectedType === "apartment" && (
              <>
                <FormControl
                  fullWidth
                  className="from-control"
                  style={{ marginTop: "3vh" }}
                >
                  <InputLabel id="service-label" style={{ color: "#1a434e" }}>
                    {" "}
                    Select Apartment
                  </InputLabel>
                  <Select
                    value={selectedApartment}
                    onChange={(e) => handleChangeApartment(e.target.value)}
                    label="Select Apartment"
                  >
                      <MenuItem value='Other'>
                       Other
                      </MenuItem>
                    {allApartment?.map((item) => (
                      <MenuItem key={item?._id} value={item?._id}>
                        {item?.apartment_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!selectOthers && (
                  <>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Tower & Floor"
                        error
                        variant="outlined"
                        {...register("tower_floor",{ required: true })}
                      />
                    {errors.tower_floor &&  <p style={{ color: "red" }}>
      Please enter Tower & Floor
    </p>}

                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Flat & House Number"
                        variant="outlined"
                        {...register("flat_house_number",{ required: true })}
                      />
                       {errors.flat_house_number &&  <p style={{ color: "red" }}>
      Please enter Flat and House
    </p>}
                    </FormControl>
                  </>
                )}
                {selectOthers && (
                  <div style={{ overflow: "scroll" }}>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Apartment Name"
                        variant="outlined"
                        {...register("apartment_name",{required:true})}
                      />
                       {errors.apartment_name &&  <p style={{ color: "red" }}>
      Please enter Apartment name
    </p>}
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Tower & Floor"
                        error
                        variant="outlined"
                        {...register("tower_floor",{ required: true })}
                      />
                    {errors.tower_floor &&  <p style={{ color: "red" }}>
      Please enter Tower & Floor
    </p>}

                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Flat & House Number"
                        variant="outlined"
                        {...register("flat_house_number",{ required: true })}
                      />
                       {errors.flat_house_number &&  <p style={{ color: "red" }}>
      Please enter Flat and House
    </p>}
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Address line "
                        variant="outlined"
                        {...register("addressLine1",{required:true})}
                      />
                       {errors.addressLine1 &&  <p style={{ color: "red" }}>
      Please enter Address
    </p>}
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Land Mark((Optional))"
                        variant="outlined"
                        {...register("landmark")}
                      />
                    
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="City"
                        variant="outlined"
                        {...register("city",{required:true})}
                      />
                       {errors.city &&  <p style={{ color: "red" }}>
      Please enter City
    </p>}
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="State"
                        variant="outlined"
                        {...register("state",{required:true})}
                      />
                       {errors.state &&  <p style={{ color: "red" }}>
      Please enter State
    </p>}
            
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Country"
                        variant="outlined"
                        {...register("country",{required:true})}
                      />
                       {errors.country &&  <p style={{ color: "red" }}>
      Please enter Country
    </p>}
            
                    </FormControl>
                    <FormControl
                      fullWidth
                      className="from-control"
                      style={{ marginTop: "3vh" }}
                    >
                      <TextField
                        label="Zip Code"
                        type="tel"
                        variant="outlined"
                        {...register("zippin",{required:true})}
                      />
                       {errors.zippin &&  <p style={{ color: "red" }}>
      Please enter Zip Pin
    </p>}
            
                    </FormControl>
                  </div>
                )}{" "}
              </>
            )}{" "}
          </div>
          <div className="independent-form-container">
            {selectedType === "independent home" && (
              <>
                <FormControl
                  fullWidth
                  className="from-control"
                  style={{ marginTop: "3vh" }}
                >
                  <TextField
                    label="Address line 1"
                    variant="outlined"
                    {...register("addressLine1",{required:true})}
                  />
                   {errors.addressLine1 &&  <p style={{ color: "red" }}>
      Please enter Address
    </p>}
            
                </FormControl>
                <FormControl
                  fullWidth
                  className="from-control"
                  style={{ marginTop: "3vh" }}
                >
                  <TextField
                    label="Land Mark(Optional)"
                    variant="outlined"
                    {...register("landmark")}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  className="from-control"
                  style={{ marginTop: "3vh" }}
                >
                  <TextField
                    label="City"
                    variant="outlined"
                    {...register("city",{required:true})}
                  />
                   {errors.city &&  <p style={{ color: "red" }}>
      Please enter City
    </p>}
                </FormControl>
                <FormControl
                  fullWidth
                  className="from-control"
                  style={{ marginTop: "3vh" }}
                >
                  <TextField
                    label="State"
                    variant="outlined"
                    {...register("state",{required:true})}
                  />
                   {errors.state &&  <p style={{ color: "red" }}>
      Please enter State
    </p>}
                </FormControl>
                <FormControl
                  fullWidth
                  className="from-control"
                  style={{ marginTop: "3vh" }}
                >
                  <TextField
                    label="Country"
                    variant="outlined"
                    {...register("country",{required:true})}
                  />
                   {errors.country &&  <p style={{ color: "red" }}>
      Please enter Country
    </p>}
                </FormControl>
                <FormControl
                  fullWidth
                  className="from-control"
                  style={{ marginTop: "3vh" }}
                >
                  <TextField
                    label="Zip Code"
                    type="tel"
                    variant="outlined"
                    {...register("zippin",{required:true})}
                  />
                   {errors.zippin &&  <p style={{ color: "red" }}>
      Please enter Zip Pin
    </p>}
                </FormControl>
              </>
            )}
          </div>
          <div className="address-page-submit-btn-container">
            <Button variant="contained" type="submit">
              {" "}
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressForm;
