import React from "react";
import "./Splash.css";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { ArrowRight } from "lucide-react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Splash = () => {
  const [count, setCount] = useState(3);
  const naviagte = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount((prevCount) => prevCount - 1);
      } else {
        clearInterval(timer);
        naviagte("/login");
      }
    }, 1000);

    // Clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, [count]);

  return (
    <div className="parent-container-of-splash">
      <div className="first-item-parent-container">
        <CardMedia
          component="img"
          image="./images/splash.jpg"
          alt="alt-text"
          style={{ height: "50vh" }}
        />
      </div>
      <div className="second-item-parent-container">
        <div className="second-item-text-container">
          <div className="text-items-first-text">
            <span style={{ fontWeight: "bold" }}>Welcome</span> To Quick Serivce Platform
          </div>
          <div className="text-items-second-text">
           Get your service booked at your fingure tips.
          </div>
        </div>
        <div className="text-items-button-item">
          <Button variant="contained"><ArrowRight/></Button>
        </div>
      </div>
    </div>
  );
};

export default Splash;
