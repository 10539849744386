import React from "react";
import { Link } from "react-router-dom";
import "./Navigation.css";

import { useState } from "react";
import Profile from "../profile/Profile";
import Service from "../service/Service";
import CardMedia from "@mui/material/CardMedia";
import { Bell } from "lucide-react";
import Decision from "../decisionpage/Decision";
import ServiceForm from "../serviceForm/ServiceForm";
import Dashboard from "../dashboard/Dashboard";

// import Box from "@mui/material/Box";

const Navigation = ({ HandelLogoutTrigger }) => {
  const [active, setActive] = useState(1);
  const [showpage, setShowpage] = useState(1);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [dicisionsend, setDisionsend] = useState(null);
  //it is an call back funtion to accept data from child compomnent
  //using state uplift
  function Updatedata(value) {
    setShowpage(value);
  }
  //made this function to accept decesion  page decision of user
  function Decisionpage(value) {
    setShowpage(value);
    console.log(value);
  }

  const handleServiceSelect = (id) => {
    setSelectedServiceId(id);
  };

  console.log("selectedServiceId", selectedServiceId);

  // handel click decison
  const HandelReciveDesison = (value) => {
    console.log("Value", value);
    setDisionsend(value);
  };

  // back to dashboard
  const BackToDashboard = (value) => {
    setShowpage(value);
    setActive(value);
  };

  return (
    <div className="parent-navbar-conatiner">
      <header>
        <div className="header-logo-container">
          <CardMedia
            component="img"
            image="./images/app-icon.png"
            alt="alt-text"
            style={{ height: "5vh", width: "10vw" }}
          />
        </div>
        <div className="header-bell-container">
          {/* <Bell size={22} style={{ paddingTop: "1.3vh" }}></Bell> */}
        </div>
      </header>
      <div className="navbar-other-display-component-conatiner">
        {showpage === 0 ? <Dashboard /> : null}
        {showpage === 2 ? (
          <Profile HandelLogoutTrigger={HandelLogoutTrigger} />
        ) : null}
        {showpage === 1 ? (
          <Service
            update={Updatedata}
            handleServiceSelect={handleServiceSelect}
          />
        ) : null}
        {showpage === 4 ? (
          <Decision
            update={Updatedata}
            decision={Decisionpage}
            sendDision={HandelReciveDesison}
          />
        ) : null}
        {showpage === 5 ? (
          <ServiceForm
            decision={Decisionpage}
            serviceId={selectedServiceId}
            dicisionsend={dicisionsend}
            BackToDashboard={BackToDashboard}
          />
        ) : null}
      </div>

      {/* <footer className="parent-down-nav-bar-container"> */}
      <div className="parent-down-nav-bar-container">
        <div className="navbar-conatiner">
          <ul className="list">
            {/* <div style={{ position: "0" }} data-indicator class="indicator">
            <div class="corners"></div>
          </div> */}
            <li
             className={active === 0 ? "active" : "inactive"}
             
              onClick={() => {
                setActive(0);
                setShowpage(0);
              }}
            >
              <div className="link "    >
                <div className="icon">
                  <img
                    src="./images/dashboard.png"
                    alt="dashborad"
                    style={{ heigh: "1.2rem", width: "1.6rem" }}
                  ></img>
                </div>
                <div className="text">Dashboard</div>
              </div>
            </li>
            <li
              className={active === 1 ? "active" : "inactive"}
              onClick={() => {
                setActive(1);
                setShowpage(1);
              }}
            >
              <div className="link">
                <div className="icon">
                  <img src="./images/service.png" alt="service"></img>
                </div>
                <div className="text">Service</div>
              </div>
            </li>
            <li
              className={active === 2 ? "active" : "inactive"}
              onClick={() => {
                setActive(2);
                setShowpage(2);
              }}
            >
              <div className="link">
                <div className="icon">
                  <img src="./images/user.png" alt="profile"></img>
                </div>
                <div className="text">Profile</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* </footer> */}
    </div>
  );
};

export default Navigation;
