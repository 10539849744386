import * as React from "react";
import Card from "@mui/material/Card";

import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Checkbox } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Check";
import "./ServiceCard.css";

export default function ServiceCard(props) {
  const { item, isSelected, handleCardClick } = props;

  return (
    <div>
      <Card
        sx={{
          margin: "4px",
          borderRadius: "19px",
          border: "none !important",
          position: "relative",
          height: "14vh",
          backgroundColor: "white !important",
          color: "black !important",
          width: "22vw",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <CardActionArea onClick={() => handleCardClick(props.item._id)}>
          {/* <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                backgroundColor: "blue",
              }}
            > */}
          {/* <CardContent
                style={{
                  backgroundColor: "#ffc801",
                  height: "5vh",
                  width: "12vw",
                  borderRadius: "50%",
                  textAlign: "center",
                  alignContent: "center",
                }}
              > */}

          <div className="service-card-imagee-conatiner">
            <div className="checkbox-container">
              {isSelected && (
                <Checkbox
                  checked={isSelected}
                  size="small"
                  sx={{
                    color: "white",
                    "& .MuiSvgIcon-root": {
                      fill: "white",
                    },
                  }}
                  // icon={<CloseIcon />}
                  checkedIcon={<CheckIcon />}

                  // sx={{
                  //   marginTop: 0,
                  //   top: "3vh",
                  //   left: "15vw",
                  // }}
                />
              )}
            </div>
            <CardMedia
              component="img"
              image={props?.item?.service_icon}
              alt="service icon"
              style={{
                height: "4vh",
                width: "9vw",
                paddingLeft: "3.3vw",
                textAlign: "center",
                marginLeft: "3vw",
                position: "relative",
              }}
            />
          </div>

          {/* </CardContent> */}
          {/* </CardContent> */}
        </CardActionArea>
        <Typography
          style={{
            paddingTop: "0.6vh",
            fontWeight: "500",
            fontSize: "0.7rem",
          }}
        >
          {props?.item?.service_name}
        </Typography>
      </Card>
    </div>
  );
}
